var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var WL,XL,YL,Cha,Dha,bM,Eha,eM,fM,gM,hM,Fha,iM,jM,lM,mM,ZL,nM,Gha,oM,Hha,pM,qM,rM,Iha,sM,tM,Jha,uM,Kha,vM,wM,Lha,yM,Mha,Nha,Oha,AM,BM,CM,DM,EM,FM,GM,HM,Pha,IM,JM,KM,MM,NM,Qha,OM,PM,QM,RM,Rha,SM,TM,UM,VM,Sha,WM,XM,YM,ZM,Tha,$M,Uha,aN,bN,cN,Vha,dN,eN,fN,gN,iN,jN,Wha,kN,lN,Xha,mN,nN,oN,pN,qN,Yha,rN,Zha,sN,tN,vN,$ha,wN,xN,yN,aia,BN,CN,bia,cia,DN,FN,GN,HN,dia,KN,LN,eia,fia,gia,MN,NN,ON,PN,QN,RN,hia,SN,iia,TN,UN,WN,YN,ZN,$N,aO,jia,cO,kia,lia,dO,eO,fO,gO,hO,mia,iO,nia,oia,jO,kO,lO,mO,nO,pia,
qia,ria,oO,sia,pO,tia,rO,sO,uia,uO,vO,wO,xO,via,AO,wia,xia,BO,CO,DO,yia,EO,FO,GO,HO,zia,JO,KO,LO,MO,NO,Aia,OO,PO,Bia,QO,RO,SO,TO,UO,VO,XO,Cia,YO,Dia,ZO,$O,aP,Eia,Fia,Gia,cP,dP,eP,Hia,fP,gP,hP,iP,Iia,Jia,kP,Kia,Lia,lP,mP,nP,oP,pP,Mia,qP,rP,sP,tP,Nia,uP,vP,wP,xP,zP,Oia,AP,BP,Pia,CP,DP,EP,Qia,FP,Ria,HP,Sia,IP,JP,KP,LP,MP,NP,OP,Tia,Uia,Via,PP,Wia,QP,RP,Xia,Yia,SP,TP,UP,Zia,VP,$ia,WP,aja,bja,XP,cja,dja,eja,$P,aQ,fja,bQ,cQ,eQ,gja,fQ,hja,gQ,hQ,iQ,ija,jja,kQ,lQ,mQ,nQ,kja,pQ,qQ,lja,rQ,sQ,tQ,uQ,vQ,wQ,xQ,yQ,
mja,zQ,AQ,nja,BQ,oja,CQ,DQ,EQ,pja,FQ,GQ,qja,rja,HQ,IQ,JQ,sja,tja,KQ,uja,vja,wja,MQ,xja,OQ,yja,zja,PQ,QQ,Aja,Bja,Cja,RQ,SQ,TQ,UQ,VQ,WQ,XQ,Dja,YQ,ZQ,Eja,$Q,Fja,Gja,Hja,aR,bR,cR,Ija,dR,eR,Jja,fR,Kja,gR,Lja,Mja,iR,jR,kR,lR,mR,Nja,nR,oR,Oja,pR,qR,rR,sR,tR,uR,vR,wR,Pja,xR,yR,AR,BR,CR,DR,ER,FR,GR,HR,IR,JR,Qja,KR,LR,MR,NR,OR,Rja,PR,QR,RR,SR,Sja,UR,Tja,Uja,WR,Vja,YR,Wja,ZR,Xja,Yja,$R,Zja,aS,cS,dS,$ja,hS,iS,aka,bka,jS,kS,lS,mS,nS,oS,pS,rS,sS,cka;
$CLJS.VL=function(a){var b=$CLJS.xo.g(a,null),c=$CLJS.vF(b,$CLJS.Ts,function(d){return $CLJS.wn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.pl,b,$CLJS.Dj,k,$CLJS.Zs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
WL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};XL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.zn($CLJS.xo.g($CLJS.pl.h(a),null));if($CLJS.n(b)){var e=WL($CLJS.zt.h(b),c);$CLJS.n(e)?(e=$CLJS.Hq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:WL($CLJS.xt.h(b),c)}return null};YL=function(a,b){return $CLJS.vd(a)||$CLJS.Il(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Cha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.pl),d=$CLJS.J.g(a,$CLJS.mj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Zs,$CLJS.mea),f=$CLJS.J.j(b,$CLJS.hea,!0),k=$CLJS.J.g(b,$CLJS.jC),l=$CLJS.J.j(b,$CLJS.hC,$CLJS.fC),m=XL(a,$CLJS.Tq.h(c),k,b);if($CLJS.n(m))return m;m=XL(a,$CLJS.kt.h(c),k,b);if($CLJS.n(m))return m;m=XL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=XL(a,function(){var t=$CLJS.eo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=XL(a,$CLJS.Tq.h(c),
l,b);if($CLJS.n(m))return m;m=XL(a,$CLJS.kt.h(c),l,b);if($CLJS.n(m))return m;d=XL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=XL(a,function(){var t=$CLJS.eo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?XL(a,e.h?e.h($CLJS.dC):e.call(null,$CLJS.dC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?XL(a,e.h?e.h($CLJS.dC):e.call(null,$CLJS.dC),l,b):f};
Dha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.ll.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.pl),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.jC);l=$CLJS.J.j(l,$CLJS.hC,$CLJS.fC);k=$CLJS.Tq.h(k);f=WL($CLJS.gC.h(k),f);f=$CLJS.n(f)?f:WL($CLJS.gC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Cha(b,c)],null)};
$CLJS.$L=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Dj);a=$CLJS.J.g(b,$CLJS.Zs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.iea,$CLJS.Qi),f=$CLJS.J.j(d,$CLJS.kea,Dha);return $CLJS.n(a)?$CLJS.fb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Qi,m);l=e.h?e.h(l):e.call(null,l);return ZL(k,c,t,l)},null,a):null};
bM=function(a,b,c){var d=$CLJS.HA($CLJS.q($CLJS.iE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.iE,$CLJS.aM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.cM=function(a){return bM($CLJS.wk,a,function(){return $CLJS.wF.h(a)})};Eha=function(){var a=dM;return bM($CLJS.Ts,a,function(){var b=$CLJS.wF.h(a),c=$CLJS.VL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
eM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};fM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,eM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,eM(c)],null);default:return eM(a)}}else return eM(a)};
gM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
hM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(gM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,fM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,fM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.nt(2,2,b))}())],null)};Fha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
iM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.Oi,Fha,$CLJS.xt,["valid instance of one of these MBQL clauses: ",$CLJS.It(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
jM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UL],null)],null)};$CLJS.kM={};lM={};mM={};$CLJS.aM=function aM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=aM.j?aM.j(k,f,c):aM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
ZL=function ZL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=YL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.eC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=YL(f,c),d=ZL.v?ZL.v(b,l,k,d):ZL.call(null,b,l,k,d),$CLJS.Nl(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Il(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jea],null),ZL.v?ZL.v(f,b,c,d):ZL.call(null,f,b,c,d)):$CLJS.n($CLJS.eC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.eC,!0],null))};nM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Gha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
oM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Hha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);pM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);qM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);rM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Iha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);sM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);tM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Jha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);uM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Kha=new $CLJS.M(null,"from","from",1815293044);vM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);wM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Lha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.xM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);yM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Mha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Nha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.zM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Oha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);AM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
BM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);CM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);DM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);EM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);FM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
GM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);HM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Pha=new $CLJS.M(null,"lon-max","lon-max",1590224717);IM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);JM=new $CLJS.r(null,"stddev","stddev",775056588,null);KM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.LM=new $CLJS.M(null,"snippet","snippet",953581994);MM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);NM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Qha=new $CLJS.M(null,"lat-field","lat-field",-830652957);OM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);PM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
QM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);RM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Rha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);SM=new $CLJS.M("location","country","location/country",1666636202);TM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
UM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);VM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Sha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);WM=new $CLJS.M(null,"unary","unary",-989314568);XM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
YM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);ZM=new $CLJS.r(null,"ceil","ceil",-184398425,null);Tha=new $CLJS.M(null,"lon-min","lon-min",-787291357);$M=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Uha=new $CLJS.M(null,"match","match",1220059550);aN=new $CLJS.r(null,"count-where","count-where",2025939247,null);bN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
cN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Vha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);dN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);eN=new $CLJS.r(null,"sum","sum",1777518341,null);fN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);gN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.hN=new $CLJS.M("date","range","date/range",1647265776);iN=new $CLJS.r(null,"between","between",-1523336493,null);jN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Wha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);kN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);lN=new $CLJS.r(null,"field","field",338095027,null);Xha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
mN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);nN=new $CLJS.r(null,"not-null","not-null",313812992,null);oN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);pN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);qN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Yha=new $CLJS.M(null,"template-tag","template-tag",310841038);
rN=new $CLJS.M(null,"invalid","invalid",412869516);Zha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);sN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);tN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.uN=new $CLJS.M(null,"context","context",-830191113);vN=new $CLJS.r(null,"get-year","get-year",704520253,null);
$ha=new $CLJS.M(null,"format-rows?","format-rows?",992129486);wN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);xN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);yN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.zN=new $CLJS.M(null,"parameters","parameters",-1229919748);aia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.AN=new $CLJS.M(null,"card","card",-1430355152);BN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);CN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);bia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);cia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);DN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.EN=new $CLJS.M("date","month-year","date/month-year",1948031290);
FN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);GN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);HN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.IN=new $CLJS.M(null,"joins","joins",1033962699);dia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.JN=new $CLJS.M(null,"source-field","source-field",933829534);KN=new $CLJS.r(null,"Field","Field",430385967,null);
LN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);eia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);fia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);gia=new $CLJS.M(null,"items","items",1031954938);MN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);NN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
ON=new $CLJS.M(null,"more","more",-2058821800);PN=new $CLJS.M(null,"first-clause","first-clause",-20953491);QN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);RN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);hia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);SN=new $CLJS.r(null,"contains","contains",-1977535957,null);
iia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);TN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);UN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.VN=new $CLJS.M(null,"widget-type","widget-type",1836256899);WN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.XN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
YN=new $CLJS.r(null,"is-null","is-null",-356519403,null);ZN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);$N=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);aO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);jia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.bO=new $CLJS.M(null,"required","required",1807647006);cO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
kia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);lia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);dO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);eO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);fO=new $CLJS.M("string","contains","string/contains",1602423827);gO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);hO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
mia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);iO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);nia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);oia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);jO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
kO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);lO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);mO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);nO=new $CLJS.r(null,"share","share",1051097594,null);pia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
qia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);ria=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);oO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);sia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);pO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
tia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.qO=new $CLJS.M(null,"collection","collection",-683361892);rO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);sO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.tO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
uia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);uO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);vO=new $CLJS.r(null,"metric","metric",2049329604,null);wO=new $CLJS.r(null,"concat","concat",-467652465,null);xO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.yO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);via=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.zO=new $CLJS.M("date","relative","date/relative",25987732);AO=new $CLJS.M("location","city","location/city",-1746973325);wia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);xia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);BO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);CO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);DO=new $CLJS.M("number","between","number/between",97700581);
yia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);EO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);FO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);GO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);HO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.IO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);
zia=new $CLJS.M(null,"metric-id","metric-id",-686486942);JO=new $CLJS.r(null,"*","*",345799209,null);KO=new $CLJS.r(null,"+","+",-740910886,null);LO=new $CLJS.r(null,"-","-",-471816912,null);MO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);NO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Aia=new $CLJS.M(null,"question","question",-1411720117);OO=new $CLJS.r(null,"asc","asc",1997386096,null);
PO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Bia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);QO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);RO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);SO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);TO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);UO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
VO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.WO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);XO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Cia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);YO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Dia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
ZO=new $CLJS.r(null,"and","and",668631710,null);$O=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);aP=new $CLJS.r(null,"round","round",-645002441,null);Eia=new $CLJS.M(null,"to","to",192099007);$CLJS.bP=new $CLJS.M("date","single","date/single",1554682003);Fia=new $CLJS.M(null,"action-id","action-id",-1727958578);Gia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);cP=new $CLJS.r(null,"exp","exp",1378825265,null);
dP=new $CLJS.r(null,"Filter","Filter",-424893332,null);eP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Hia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);fP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);gP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);hP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
iP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.jP=new $CLJS.M(null,"source-table","source-table",-225307692);Iia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Jia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);kP=new $CLJS.r(null,"floor","floor",-772394748,null);Kia=new $CLJS.M(null,"middleware","middleware",1462115504);
Lia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);lP=new $CLJS.M(null,"requires-features","requires-features",-101116256);mP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);nP=new $CLJS.M(null,"clause-name","clause-name",-996419059);oP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);pP=new $CLJS.r(null,"now","now",-9994004,null);
Mia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);qP=new $CLJS.r(null,"not","not",1044554643,null);rP=new $CLJS.r(null,"avg","avg",1837937727,null);sP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);tP=new $CLJS.M(null,"max-results","max-results",-32858165);Nia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);uP=new $CLJS.r(null,"case","case",-1510733573,null);
vP=new $CLJS.r(null,"distinct","distinct",-148347594,null);wP=new $CLJS.r(null,"get-second","get-second",-425414791,null);xP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.yP=new $CLJS.M(null,"join-alias","join-alias",1454206794);zP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Oia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
AP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);BP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Pia=new $CLJS.M(null,"original","original",-445386197);CP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);DP=new $CLJS.r(null,"abs","abs",1394505050,null);EP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Qia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
FP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Ria=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.GP=new $CLJS.M(null,"date","date",-1463434462);HP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Sia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);IP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
JP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);KP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);LP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);MP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);NP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);OP=new $CLJS.r(null,"or","or",1876275696,null);
Tia=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Uia=new $CLJS.M(null,"constraints","constraints",422775616);Via=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);PP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Wia=new $CLJS.M(null,"csv-download","csv-download",2141432084);QP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
RP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Xia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Yia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);SP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);TP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
UP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Zia=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);VP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);$ia=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);WP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);aja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
bja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);XP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);cja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);dja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);eja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.YP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.ZP=new $CLJS.M(null,"database","database",1849087575);$P=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);aQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);fja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);bQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
cQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.dQ=new $CLJS.M(null,"expressions","expressions",255689909);eQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);gja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);fQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
hja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);gQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);hQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);iQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);ija=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);jja=new $CLJS.M(null,"action","action",-811238024);
kQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);lQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);mQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);nQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.oQ=new $CLJS.M(null,"native","native",-613060878);kja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);pQ=new $CLJS.M(null,"page","page",849072397);
qQ=new $CLJS.r(null,"length","length",-2065447907,null);lja=new $CLJS.M(null,"dashboard","dashboard",-631747508);rQ=new $CLJS.r(null,"get-week","get-week",752472178,null);sQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);tQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);uQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);vQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
wQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);xQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);yQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);mja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);zQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);AQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
nja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);BQ=new $CLJS.M(null,"internal","internal",-854870097);oja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);CQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);DQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
EQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);pja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);FQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);GQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);qja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
rja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);HQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);IQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);JQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
sja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);tja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);KQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.LQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);uja=new $CLJS.M(null,"public-question","public-question",629369976);vja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
wja=new $CLJS.M(null,"binary","binary",-1802232288);MQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);xja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.NQ=new $CLJS.M(null,"source-query","source-query",198004422);OQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);yja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
zja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);PQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);QQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Aja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Bja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Cja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);RQ=new $CLJS.M(null,"amount","amount",364489504);SQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);TQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);UQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);VQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
WQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);XQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Dja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);YQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);ZQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Eja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
$Q=new $CLJS.M("string","\x3d","string/\x3d",983744235);Fja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Gja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Hja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);aR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);bR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
cR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Ija=new $CLJS.M(null,"lat-min","lat-min",1630784161);dR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);eR=new $CLJS.r(null,"inside","inside",-681932758,null);Jja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);fR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Kja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);gR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Lja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Mja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.hR=new $CLJS.M(null,"card-id","card-id",-1770060179);iR=new $CLJS.M(null,"variadic","variadic",882626057);
jR=new $CLJS.r(null,"upper","upper",1886775433,null);kR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);lR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);mR=new $CLJS.r(null,"optional","optional",-600484260,null);Nja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);nR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
oR=new $CLJS.M(null,"sugar","sugar",-73788488);Oja=new $CLJS.M(null,"lat-max","lat-max",841568226);pR=new $CLJS.r(null,"power","power",702679448,null);qR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);rR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);sR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
tR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);uR=new $CLJS.r(null,"median","median",-2084869638,null);vR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);wR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Pja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);xR=new $CLJS.M(null,"y","y",-1757859776);yR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.zR=new $CLJS.M(null,"binning","binning",1709835866);AR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);BR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);CR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);DR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);ER=new $CLJS.r(null,"Reference","Reference",2024574086,null);FR=new $CLJS.M(null,"b","b",1482224470);
GR=new $CLJS.M(null,"a","a",-2123407586);HR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);IR=new $CLJS.r(null,"replace","replace",853943757,null);JR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Qja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);KR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
LR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);MR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);NR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);OR=new $CLJS.r(null,"segment","segment",675610331,null);Rja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);PR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
QR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);RR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);SR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Sja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.TR=new $CLJS.M(null,"order-by","order-by",1527318070);UR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Tja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Uja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.VR=new $CLJS.M(null,"condition","condition",1668437652);WR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Vja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);YR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Wja=new $CLJS.M(null,"card-name","card-name",-2035606807);ZR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Xja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Yja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);$R=new $CLJS.r(null,"log","log",45015523,null);Zja=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
aS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.bS=new $CLJS.M(null,"database_type","database_type",-54700895);cS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);dS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.eS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.fS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.gS=new $CLJS.M("date","all-options","date/all-options",549325958);
$ja=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);hS=new $CLJS.M("location","state","location/state",-114378652);iS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);aka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);bka=new $CLJS.M(null,"lon-field","lon-field",517872067);jS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
kS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);lS=new $CLJS.M(null,"numeric","numeric",-1495594714);mS=new $CLJS.r(null,"variable","variable",1359185035,null);nS=new $CLJS.r(null,"lower","lower",-1534114948,null);oS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);pS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.qS=new $CLJS.M(null,"limit","limit",-1355822363);
rS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);sS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);cka=new $CLJS.M(null,"pulse","pulse",-244494476);var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KE],null),vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null),wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rE],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null),dka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null),eka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.PI],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TI],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KI],null),CS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.pi,null,$CLJS.fu,null,$CLJS.oj,null,$CLJS.di,null,$CLJS.Gi,null,$CLJS.xj,null,$CLJS.lk,null,$CLJS.Fj,null,$CLJS.ki,null,$CLJS.Ej,null,$CLJS.qi,null,$CLJS.Ai,null],null),null),DS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.pk,null,$CLJS.dH,null,$CLJS.di,null,$CLJS.wi,null,$CLJS.gH,null,$CLJS.ij,null,$CLJS.Ui,null],null),null),
ES=$CLJS.gu.g(CS,DS),fka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"date bucketing unit"],null)],null),CS),gka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"time bucketing unit"],null)],null),DS),FS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"datetime bucketing unit"],null)],null),ES),GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tJ],null),hka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,
1,[$CLJS.xt,"temporal extract unit"],null),$CLJS.hH,$CLJS.Ai,$CLJS.lk,$CLJS.eJ,$CLJS.zJ,$CLJS.AJ,$CLJS.Fj,$CLJS.Gi,$CLJS.wi,$CLJS.ij,$CLJS.eH],null),ika=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"datetime-diff unit"],null),$CLJS.dH,$CLJS.Ui,$CLJS.pk,$CLJS.fu,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ki],null),HS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"temporal-extract week extraction mode"],null),$CLJS.ZI,$CLJS.hJ,$CLJS.mJ],null),IS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"relative-datetime unit"],null),$CLJS.di,$CLJS.Ui,$CLJS.pk,$CLJS.fu,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ki],null),PS,kka,XS,YS,ZS,$S,aT,bT,cT,OU,mka,PU,nka,oka,QU,pka,qka,rka;$CLJS.X(YM,hM($CLJS.iJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.$v],null),$CLJS.Js],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,IS],null)])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);
$CLJS.X(CP,hM($CLJS.gJ,$CLJS.H(["n",$CLJS.Js,"unit",IS])));var jka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CP],null);
$CLJS.X(sN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"valid :absolute-datetime clause",$CLJS.Oi,function(a){if($CLJS.Va(gM($CLJS.cJ,a)))a=rN;else{a=$CLJS.hd(a);var b=$CLJS.cM(zS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.GP:$CLJS.jJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[rN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GP,hM($CLJS.cJ,
$CLJS.H(["date",zS,"unit",fka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,hM($CLJS.cJ,$CLJS.H(["datetime",AS,"unit",FS]))],null)],null));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.X(HN,hM($CLJS.My,$CLJS.H(["time",BS,"unit",gka])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HN],null),MS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"date or datetime literal"],null),KS,AS,zS],null);
$CLJS.X(sM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"time literal"],null),LS,BS],null));$CLJS.X(jS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"temporal literal"],null),MS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null)],null));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jS],null);
$CLJS.X(Eja,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof KS?new $CLJS.Gc(function(){return KS},$CLJS.od(eia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,BQ,$CLJS.nj,$CLJS.xk,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iQ,"metabase/mbql/schema.cljc",69,$CLJS.cJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:iQ}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof JS?new $CLJS.Gc(function(){return JS},$CLJS.od($ja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,
$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",29,$CLJS.iJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iJ,$CLJS.qk,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.$v],null),$CLJS.Js],null)),$CLJS.si,$CLJS.U($CLJS.Pj,$CLJS.U(mR,YO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:AP}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,BQ,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aS,"metabase/mbql/schema.cljc",27,$CLJS.My,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.My,$CLJS.My,$CLJS.U($CLJS.Pj,Dja),$CLJS.si,$CLJS.U($CLJS.Pj,via)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:aS}(),LS],null)])));
$CLJS.X(ZR,hM($CLJS.Dj,$CLJS.H(["value",$CLJS.Os,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bS,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ws,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.si,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,tS],null)],null)],null)],null)])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZR],null);$CLJS.X(HO,hM($CLJS.vB,$CLJS.H(["expression-name",tS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.hk],null)])));
PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);
kka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.ME);c=$CLJS.J.g(c,$CLJS.tE);return $CLJS.E.g(b,$CLJS.tE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.ME);c=$CLJS.J.g(c,$CLJS.LE);return $CLJS.E.g(b,$CLJS.LE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ME,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"binning strategy"],null),$CLJS.tE,$CLJS.LE,$CLJS.di],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.LE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.Al,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Vl)],null)],null)],null)],null)));$CLJS.QS=function QS(a){switch(arguments.length){case 1:return QS.h(arguments[0]);case 2:return QS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.QS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.yB);b=$CLJS.J.g(b,$CLJS.xG);return $CLJS.QS.g(a,b)};$CLJS.QS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.yC(a,$CLJS.ZC)?CS:$CLJS.yC(a,$CLJS.YD)?DS:$CLJS.yC(a,$CLJS.yD)?ES:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.QS.A=2;
$CLJS.X(nR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yB,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,kka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.QS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.ME)],null)],null));
$CLJS.X(PR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.yB);return"string"===typeof c?b:!0}],null)],null)}(hM($CLJS.rG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,yS,tS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nR],null)],null)]))));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);
$CLJS.X(PP,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(TN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",60,$CLJS.vB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vB,$CLJS.fS,$CLJS.U($CLJS.Pj,$M),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:DN}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},
$CLJS.od(kO,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.vr,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,$CLJS.ui,$CLJS.jk,$CLJS.nk],["0.39.0",$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",51,$CLJS.rG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:lN}(),RS],null)])));
$CLJS.SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.X(nM,hM($CLJS.gG,$CLJS.H(["aggregation-clause-index",$CLJS.Js,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.hk],null)])));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);
$CLJS.X(UM,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yR,"metabase/mbql/schema.cljc",23,$CLJS.gG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gG,aka,$CLJS.U($CLJS.Pj,$CLJS.Js),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:yR}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},
$CLJS.od(TN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DN,"metabase/mbql/schema.cljc",60,$CLJS.vB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vB,$CLJS.fS,$CLJS.U($CLJS.Pj,$M),
$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:DN}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.od(kO,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.vr,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,
$CLJS.ui,$CLJS.jk,$CLJS.nk],["0.39.0",$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",51,$CLJS.rG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:lN}(),RS],null)])));
var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null),VS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Kt,null,$CLJS.JG,null,$CLJS.KF,null,$CLJS.PG,null,$CLJS.RF,null,$CLJS.uG,null,$CLJS.aG,null,$CLJS.Qt,null,$CLJS.HG,null,$CLJS.tG,null,$CLJS.QG,null],null),null),WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);
$CLJS.X(uM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return"string"===typeof a?$CLJS.Yj:$CLJS.n(gM(VS,a))?NM:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[NM,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.SS],null)],null));XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);
YS=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.NF,null,$CLJS.yJ,null,$CLJS.cs,null,$CLJS.uJ,null,$CLJS.rJ,null,$CLJS.eG,null,$CLJS.qt,null,$CLJS.JF,null,$CLJS.vJ,null,$CLJS.oJ,null,$CLJS.uG,null,$CLJS.MG,null,$CLJS.sJ,null,$CLJS.wG,null,$CLJS.PF,null,$CLJS.aJ,null,$CLJS.bs,null,$CLJS.SF,null,$CLJS.aG,null,$CLJS.VI,null,$CLJS.Wx,null,$CLJS.bJ,null,$CLJS.nJ,null,$CLJS.fJ,null,$CLJS.OG,null,$CLJS.pG,null],null),null);
ZS=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.Zr,null,$CLJS.Tr,null,$CLJS.xs,null,$CLJS.zs,null,$CLJS.Vr,null,$CLJS.IG,null,$CLJS.Es,null,$CLJS.$r,null,$CLJS.Xr,null],null),null);$S=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Yn,null,$CLJS.GG,null,$CLJS.AG,null,$CLJS.sG,null,$CLJS.LG,null,$CLJS.NG,null,$CLJS.XF,null,$CLJS.cG,null,$CLJS.zM,null,$CLJS.YF,null,$CLJS.FG,null,$CLJS.yG,null,$CLJS.ik,null,$CLJS.Yx,null,$CLJS.lG,null,$CLJS.mG,null,$CLJS.EG,null],null),null);
aT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.kJ,null,$CLJS.XI,null,$CLJS.wJ,null,$CLJS.bs,null,$CLJS.qJ,null],null),null);bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fR],null);cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FN],null);$CLJS.dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);$CLJS.eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);
$CLJS.X(sR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"numeric expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.n(gM(YS,a))?iP:$CLJS.n(gM($S,a))?$CLJS.gG:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.rG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,$CLJS.Al],null),new $CLJS.P(null,2,5,$CLJS.Q,[iP,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,$CLJS.eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rG,
$CLJS.SS],null)],null));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null);
$CLJS.X(CQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"datetime expression argument",$CLJS.Oi,function(a){return $CLJS.n(gM($S,a))?$CLJS.gG:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.n(gM(aT,a))?MN:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,$CLJS.eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[MN,$CLJS.dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,MS,$CLJS.SS],null)],null)],null));
var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);
$CLJS.X(eQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.Cd(a)?$CLJS.Ns:$CLJS.n(gM(ZS,a))?uQ:$CLJS.n(gM(YS,a))?iP:$CLJS.n(gM(aT,a))?MN:"string"===typeof a?$CLJS.Yj:$CLJS.n(gM(VS,a))?NM:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,$CLJS.Al],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,$CLJS.Ns],null),new $CLJS.P(null,2,5,$CLJS.Q,[uQ,cT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[iP,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[MN,$CLJS.dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[NM,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.SS],null)],null));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null);$CLJS.X(cQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"numeric expression arg or interval"],null),jka,fT],null));
var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null);$CLJS.X(FM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"int greater than zero or numeric expression",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,bT],null)],null));var lka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FM],null);
$CLJS.X(NN,hM($CLJS.uG,$CLJS.H(["a",hT,"b",hT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,hT],null)])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null);$CLJS.X(rM,hM($CLJS.KF,$CLJS.H(["s",XS,"start",lka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,fT],null)])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);$CLJS.X(vQ,hM($CLJS.Wx,$CLJS.H(["s",XS])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);$CLJS.X(TM,hM($CLJS.HG,$CLJS.H(["s",XS])));
var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.X(XM,hM($CLJS.QG,$CLJS.H(["s",XS])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.X(pN,hM($CLJS.JG,$CLJS.H(["s",XS])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);$CLJS.X(aO,hM($CLJS.Kt,$CLJS.H(["s",XS])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aO],null);$CLJS.X(KM,hM($CLJS.Qt,$CLJS.H(["s",XS])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);
$CLJS.X(rR,hM($CLJS.PG,$CLJS.H(["s",XS,"match",$CLJS.Yj,"replacement",$CLJS.Yj])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rR],null);$CLJS.X(oS,hM($CLJS.RF,$CLJS.H(["a",XS,"b",XS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,XS],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oS],null);$CLJS.X(QO,hM($CLJS.tG,$CLJS.H(["s",XS,"pattern",$CLJS.Yj])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QO],null);
$CLJS.X(NR,hM($CLJS.bs,$CLJS.H(["x",iT,"y",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,iT],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(LR,hM($CLJS.qt,$CLJS.H(["x",fT,"y",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,iT],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);$CLJS.X(KR,hM($CLJS.MG,$CLJS.H(["x",fT,"y",fT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fT],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);
$CLJS.X(MR,hM($CLJS.cs,$CLJS.H(["x",fT,"y",fT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fT],null)])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);$CLJS.X(bQ,hM($CLJS.pG,$CLJS.H(["x",fT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);$CLJS.X(VQ,hM($CLJS.NF,$CLJS.H(["x",fT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(rS,hM($CLJS.wG,$CLJS.H(["x",fT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rS],null);$CLJS.X(gP,hM($CLJS.SF,$CLJS.H(["x",fT])));
var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gP],null);$CLJS.X(mP,hM($CLJS.PF,$CLJS.H(["x",fT,"y",fT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mP],null);$CLJS.X(JP,hM($CLJS.eG,$CLJS.H(["x",fT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);$CLJS.X(GM,hM($CLJS.JF,$CLJS.H(["x",fT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null);$CLJS.X(hQ,hM($CLJS.OG,$CLJS.H(["x",fT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);
$CLJS.X(fN,hM($CLJS.fJ,$CLJS.H(["datetime-x",gT,"datetime-y",gT,"unit",ika])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);$CLJS.X(xN,hM($CLJS.uJ,$CLJS.H(["datetime",gT,"unit",hka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,HS],null)])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);$CLJS.X(oP,hM($CLJS.bJ,$CLJS.H(["date",gT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oP],null);$CLJS.X(QP,hM($CLJS.yJ,$CLJS.H(["date",gT])));
var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);$CLJS.X(tM,hM($CLJS.VI,$CLJS.H(["date",gT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);$CLJS.X(pS,hM($CLJS.oJ,$CLJS.H(["date",gT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,HS],null)])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pS],null);$CLJS.X(BO,hM($CLJS.nJ,$CLJS.H(["date",gT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BO],null);$CLJS.X(KP,hM($CLJS.sJ,$CLJS.H(["date",gT])));
var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.X(bN,hM($CLJS.vJ,$CLJS.H(["datetime",gT])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);$CLJS.X(GO,hM($CLJS.rJ,$CLJS.H(["datetime",gT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GO],null);$CLJS.X(VM,hM($CLJS.aJ,$CLJS.H(["datetime",gT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);$CLJS.X(lO,hM($CLJS.XI,$CLJS.H(["datetime",gT,"to",GS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,GS],null)])));
var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null),ST=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"datetime arithmetic unit"],null),$CLJS.gH,$CLJS.dH,$CLJS.Ui,$CLJS.pk,$CLJS.fu,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ki],null);$CLJS.X(qM,hM($CLJS.qJ,$CLJS.H(["datetime",gT,"amount",fT,"unit",ST])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);
$CLJS.X(OM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return hM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.kJ));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);$CLJS.X(pM,hM($CLJS.wJ,$CLJS.H(["datetime",gT,"amount",fT,"unit",ST])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);
$CLJS.X(HQ,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.IE,$CLJS.U($CLJS.Pj,$P),xR,$CLJS.U($CLJS.Pj,$P),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,$P))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:KO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},
$CLJS.od(Mia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[eO,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",67,$CLJS.qJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,kS),RQ,$CLJS.U($CLJS.Pj,
WQ),$CLJS.si,$CLJS.U($CLJS.Pj,dR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:RP}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(Gha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,
$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[eO,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",72,$CLJS.wJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.wJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,kS),RQ,$CLJS.U($CLJS.Pj,WQ),$CLJS.si,$CLJS.U($CLJS.Pj,dR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(VT)?VT.H:null])):null));
return $CLJS.n(a)?a:iS}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.XI,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",71,$CLJS.XI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XI,$CLJS.jJ,$CLJS.U($CLJS.Pj,kS),Eia,$CLJS.U($CLJS.Pj,AR),Kha,$CLJS.U($CLJS.Pj,$CLJS.U(mR,AR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:LN}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(mja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",
45,$CLJS.kJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:pP}(),UT],null)])));$CLJS.WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);$CLJS.X(vM,hM($CLJS.Es,$CLJS.H(["first-clause",$CLJS.WT,"second-clause",$CLJS.WT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.WT],null)])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);
$CLJS.X(hO,hM($CLJS.xs,$CLJS.H(["first-clause",$CLJS.WT,"second-clause",$CLJS.WT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.WT],null)])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null);$CLJS.X(QM,hM($CLJS.zs,$CLJS.H(["clause",$CLJS.WT])));
var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null),$T=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,3,[$CLJS.xt,":field or :expression reference or :relative-datetime",$CLJS.zt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Oi,function(a){return $CLJS.n(gM($CLJS.iJ,a))?$CLJS.iJ:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.SS],null)],null);
$CLJS.X(EQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.xt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xs,$CLJS.Ns,$CLJS.Al,$CLJS.Yj,NS,$T,hT,OS],null)],null));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);
$CLJS.X(VO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"order comparable",$CLJS.Oi,function(a){return $CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xs,$CLJS.Al,$CLJS.Yj,NS,hT,$T],null)],null)],null));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VO],null);
$CLJS.X(DR,hM($CLJS.$r,$CLJS.H(["field",aU,"value-or-field",aU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,aU],null)])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DR],null);$CLJS.X(HR,hM($CLJS.IG,$CLJS.H(["field",aU,"value-or-field",aU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,aU],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(CR,hM($CLJS.Xr,$CLJS.H(["field",bU,"value-or-field",bU])));
var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CR],null);$CLJS.X(BR,hM($CLJS.Tr,$CLJS.H(["field",bU,"value-or-field",bU])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BR],null);$CLJS.X(qN,hM($CLJS.Zr,$CLJS.H(["field",bU,"value-or-field",bU])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);$CLJS.X(wN,hM($CLJS.Vr,$CLJS.H(["field",bU,"value-or-field",bU])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wN],null);$CLJS.X(mQ,hM($CLJS.UF,$CLJS.H(["field",bU,"min",bU,"max",bU])));
var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(OQ,hM($CLJS.$F,$CLJS.H(["lat-field",bU,"lon-field",bU,"lat-max",bU,"lon-min",bU,"lat-min",bU,"lon-max",bU])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.X($N,hM($CLJS.TF,$CLJS.H(["field",$CLJS.SS])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$N],null);$CLJS.X(PO,hM($CLJS.dG,$CLJS.H(["field",$CLJS.SS])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PO],null);$CLJS.X(rO,hM($CLJS.VF,$CLJS.H(["field",$CLJS.SS])));
var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rO],null);$CLJS.X(yM,hM($CLJS.bG,$CLJS.H(["field",$CLJS.SS])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null),oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null);$CLJS.X(EO,hM($CLJS.iG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,oU],null)])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EO],null);
$CLJS.X(xP,hM($CLJS.CG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,oU],null)])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xP],null);$CLJS.X(pO,hM($CLJS.qG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,oU],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X(fQ,hM($CLJS.jG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,oU],null)])));
var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);$CLJS.X(HM,hM($CLJS.DG,$CLJS.H(["field",$CLJS.SS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.Js,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,$CLJS.$v,$CLJS.by,$CLJS.ZF],null)],null),"unit",IS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null)],null)])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);
$CLJS.X(xQ,hM($CLJS.QF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sE],null),tS],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);
$CLJS.X(FN,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZO,"metabase/mbql/schema.cljc",15,$CLJS.Es,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,PN,$CLJS.U($CLJS.Pj,dP),HP,$CLJS.U($CLJS.Pj,dP),ZN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:ZO}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},
$CLJS.od(LP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",14,$CLJS.xs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,PN,$CLJS.U($CLJS.Pj,dP),HP,$CLJS.U($CLJS.Pj,dP),ZN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:OP}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(yQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",15,$CLJS.zs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.eS,$CLJS.U($CLJS.Pj,dP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:qP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==
typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",13,$CLJS.Xr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),bR,$CLJS.U($CLJS.Pj,RN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:SO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(YR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",14,$CLJS.Zr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),bR,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:RO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&
"undefined"!==typeof mM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.rG,$CLJS.U($CLJS.Pj,
RN),bR,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:TO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(QR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],
[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),bR,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:$O}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.$r,$CLJS.rG,$CLJS.U($CLJS.Pj,cS),bR,$CLJS.U($CLJS.Pj,cS),FQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:UO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(gN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,
$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CN,"metabase/mbql/schema.cljc",14,$CLJS.IG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IG,$CLJS.rG,$CLJS.U($CLJS.Pj,cS),bR,$CLJS.U($CLJS.Pj,cS),FQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(dU)?dU.H:null])):null));
return $CLJS.n(a)?a:CN}(),dU],null)])));
$CLJS.X(MP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"valid filter expression",$CLJS.Oi,function(a){return $CLJS.n(gM(aT,a))?$CLJS.jJ:$CLJS.n(gM(YS,a))?lS:$CLJS.n(gM(VS,a))?$CLJS.Yj:$CLJS.n(gM(ZS,a))?$CLJS.Ns:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,$CLJS.dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[lS,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,iM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",
15,$CLJS.Es,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,PN,$CLJS.U($CLJS.Pj,dP),HP,$CLJS.U($CLJS.Pj,dP),ZN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:ZO}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(LP,new $CLJS.h(null,1,[$CLJS.ak,
!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",14,$CLJS.xs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,PN,$CLJS.U($CLJS.Pj,dP),HP,$CLJS.U($CLJS.Pj,dP),ZN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(YT)?
YT.H:null])):null));return $CLJS.n(a)?a:OP}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(yQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qP,"metabase/mbql/schema.cljc",15,$CLJS.zs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.eS,$CLJS.U($CLJS.Pj,dP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:qP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$r,$CLJS.rG,$CLJS.U($CLJS.Pj,cS),bR,$CLJS.U($CLJS.Pj,cS),FQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(cU)?cU.H:null])):
null));return $CLJS.n(a)?a:UO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(gN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CN,"metabase/mbql/schema.cljc",14,$CLJS.IG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IG,$CLJS.rG,$CLJS.U($CLJS.Pj,cS),bR,$CLJS.U($CLJS.Pj,cS),FQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:CN}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},
$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",13,$CLJS.Xr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),bR,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:SO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TO,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),bR,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:TO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(YR,new $CLJS.h(null,
1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",14,$CLJS.Zr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),bR,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(gU)?gU.H:null])):
null));return $CLJS.n(a)?a:RO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(QR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),bR,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:$O}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(dja,new $CLJS.h(null,
1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iN,"metabase/mbql/schema.cljc",19,$CLJS.UF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UF,$CLJS.rG,$CLJS.U($CLJS.Pj,RN),$CLJS.Yn,$CLJS.U($CLJS.Pj,RN),$CLJS.ik,$CLJS.U($CLJS.Pj,RN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:iN}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(fja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gR,"metabase/mbql/schema.cljc",23,$CLJS.iG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iG,$CLJS.rG,$CLJS.U($CLJS.Pj,aR),BN,$CLJS.U($CLJS.Pj,aR),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,wQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:gR}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},
$CLJS.od(hia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GN,"metabase/mbql/schema.cljc",21,$CLJS.CG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CG,$CLJS.rG,$CLJS.U($CLJS.Pj,aR),BN,$CLJS.U($CLJS.Pj,aR),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,wQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:GN}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(tia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",20,$CLJS.qG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qG,$CLJS.rG,$CLJS.U($CLJS.Pj,aR),BN,$CLJS.U($CLJS.Pj,aR),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,wQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:SN}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==
typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wM,"metabase/mbql/schema.cljc",36,$CLJS.jG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.jG,$CLJS.rG,$CLJS.U($CLJS.Pj,aR),BN,$CLJS.U($CLJS.Pj,aR),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,wQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:wM}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",26,$CLJS.$F,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.$F,Qha,$CLJS.U($CLJS.Pj,RN),bka,$CLJS.U($CLJS.Pj,RN),Oja,$CLJS.U($CLJS.Pj,RN),Tha,$CLJS.U($CLJS.Pj,RN),Ija,$CLJS.U($CLJS.Pj,RN),Pha,$CLJS.U($CLJS.Pj,
RN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:eR}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",28,$CLJS.VF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,$CLJS.rG,$CLJS.U($CLJS.Pj,KN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:zP}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",29,$CLJS.bG,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,$CLJS.rG,$CLJS.U($CLJS.Pj,KN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:nQ}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(Tja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",27,$CLJS.TF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.rG,$CLJS.U($CLJS.Pj,KN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:YN}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(Gia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",28,$CLJS.dG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,$CLJS.rG,$CLJS.U($CLJS.Pj,KN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:nN}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",33,$CLJS.DG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.DG,$CLJS.rG,$CLJS.U($CLJS.Pj,KN),$CLJS.qk,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.Js,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,$CLJS.$v,$CLJS.by,$CLJS.ZF],null)],null)),$CLJS.si,$CLJS.U($CLJS.Pj,YO),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,ija))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:MQ}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OR,"metabase/mbql/schema.cljc",27,$CLJS.QF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,Xha,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,yja,$M],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:OR}(),uU],null)]))],null)],null));
$CLJS.X(zQ,hM($CLJS.aG,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.xt,":case subclause"],null),$CLJS.WT,hT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),hT],null)],null)],null)])));
var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);
$CLJS.X(fR,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.IE,$CLJS.U($CLJS.Pj,$P),xR,$CLJS.U($CLJS.Pj,$P),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,$P))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:KO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},
$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",51,$CLJS.qt,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qt,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ),
xR,$CLJS.U($CLJS.Pj,$P),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,$P))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:LO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM?new $CLJS.Gc(function(){return wT},$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,nP,$CLJS.Ti,$CLJS.nj,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.kA,"metabase/mbql/schema.cljc",$CLJS.MG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.MG,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ),xR,$CLJS.U($CLJS.Pj,WQ),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,WQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.kA}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JO,"metabase/mbql/schema.cljc",51,$CLJS.cs,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cs,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ),xR,$CLJS.U($CLJS.Pj,WQ),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,WQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:JO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},
$CLJS.od(JQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",58,$CLJS.uG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,GR,$CLJS.U($CLJS.Pj,BM),FR,$CLJS.U($CLJS.Pj,
BM),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,BM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:YQ}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,
$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",56,$CLJS.Wx,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wx,$CLJS.sy,$CLJS.U($CLJS.Pj,aR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:qQ}(),lT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kP,"metabase/mbql/schema.cljc",55,$CLJS.pG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:kP}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZM,"metabase/mbql/schema.cljc",54,$CLJS.NF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(zT)?
zT.H:null])):null));return $CLJS.n(a)?a:ZM}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",55,$CLJS.wG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:aP}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",53,$CLJS.SF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.SF,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:DP}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,
jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",69,$CLJS.PF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.PF,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ),xR,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:pR}(),CT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(Jia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FO,"metabase/mbql/schema.cljc",68,$CLJS.eG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:FO}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(Nja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",67,$CLJS.JF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(ET)?
ET.H:null])):null));return $CLJS.n(a)?a:cP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Wha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$R,"metabase/mbql/schema.cljc",67,$CLJS.OG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.IE,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:$R}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",61,$CLJS.aG,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.aG,$CLJS.ix,$CLJS.U($CLJS.Pj,dO),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,NP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:uP}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,
$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",65,$CLJS.fJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fJ,kia,$CLJS.U($CLJS.Pj,kS),lia,$CLJS.U($CLJS.Pj,kS),$CLJS.si,$CLJS.U($CLJS.Pj,Ria)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:EP}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(vja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",71,$CLJS.uJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,kS),$CLJS.si,$CLJS.U($CLJS.Pj,Sja),$CLJS.yi,$CLJS.U($CLJS.Pj,$CLJS.U(mR,sS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:XP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Cia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vN,"metabase/mbql/schema.cljc",71,$CLJS.bJ,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bJ,$CLJS.GP,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:vN}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(ria,new $CLJS.h(null,1,[$CLJS.ak,!0],
null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",74,$CLJS.yJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yJ,$CLJS.GP,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:VP}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),
$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",72,$CLJS.VI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VI,$CLJS.GP,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:sQ}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==
typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",
71,$CLJS.oJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.oJ,$CLJS.GP,$CLJS.U($CLJS.Pj,kS),$CLJS.yi,$CLJS.U($CLJS.Pj,$CLJS.U(mR,sS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:rQ}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.ak,
!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",70,$CLJS.nJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nJ,$CLJS.GP,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:kQ}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Sia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),
$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",78,$CLJS.sJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,$CLJS.GP,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:aQ}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==
typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Qia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CM,"metabase/mbql/schema.cljc",
71,$CLJS.vJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:CM}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(zja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,
$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dS,"metabase/mbql/schema.cljc",73,$CLJS.rJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(PT)?PT.H:null])):null));
return $CLJS.n(a)?a:dS}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Nia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,
[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",73,$CLJS.aJ,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,kS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:wP}(),QT],null)])));
$CLJS.X(RM,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AQ,"metabase/mbql/schema.cljc",59,$CLJS.KF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KF,$CLJS.sy,$CLJS.U($CLJS.Pj,aR),$CLJS.Fv,$CLJS.U($CLJS.Pj,nia),$CLJS.Wx,$CLJS.U($CLJS.Pj,$CLJS.U(mR,WQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:AQ}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},
$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",54,$CLJS.HG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HG,$CLJS.sy,$CLJS.U($CLJS.Pj,aR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:XQ}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",55,$CLJS.JG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,$CLJS.sy,$CLJS.U($CLJS.Pj,aR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:GQ}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",
55,$CLJS.QG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QG,$CLJS.sy,$CLJS.U($CLJS.Pj,aR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:IP}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(Iha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,
$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IR,"metabase/mbql/schema.cljc",57,$CLJS.PG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PG,$CLJS.sy,$CLJS.U($CLJS.Pj,aR),Uha,$CLJS.U($CLJS.Pj,$CLJS.Yj),$CLJS.rA,$CLJS.U($CLJS.Pj,$CLJS.Yj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:IR}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(tja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nS,"metabase/mbql/schema.cljc",55,$CLJS.Qt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qt,$CLJS.sy,$CLJS.U($CLJS.Pj,aR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:nS}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==
typeof mM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(Qja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",55,$CLJS.Kt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kt,$CLJS.sy,$CLJS.U($CLJS.Pj,aR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:jR}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,
$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",56,$CLJS.RF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RF,GR,$CLJS.U($CLJS.Pj,aR),FR,$CLJS.U($CLJS.Pj,aR),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,aR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:wO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.dQ,"null",$CLJS.Oda,"null"],
null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tN,"metabase/mbql/schema.cljc",74,$CLJS.tG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tG,$CLJS.sy,$CLJS.U($CLJS.Pj,aR),$CLJS.qA,$CLJS.U($CLJS.Pj,$CLJS.Yj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:tN}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==
typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},$CLJS.od(JQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
YQ,"metabase/mbql/schema.cljc",58,$CLJS.uG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,GR,$CLJS.U($CLJS.Pj,BM),FR,$CLJS.U($CLJS.Pj,BM),ON,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Mi,BM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:YQ}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},
$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",61,$CLJS.aG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aG,$CLJS.ix,$CLJS.U($CLJS.Pj,dO),
$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,NP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:uP}(),vU],null)])));
$CLJS.wU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,":field or :expression reference or expression",$CLJS.Oi,function(a){return $CLJS.n(gM(YS,a))?lS:$CLJS.n(gM(VS,a))?$CLJS.Yj:$CLJS.n(gM(ZS,a))?$CLJS.Ns:$CLJS.n(gM(aT,a))?$CLJS.jJ:$CLJS.n(gM($CLJS.aG,a))?$CLJS.aG:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[lS,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,$CLJS.dT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.SS],null)],null);$CLJS.X(RR,hM($CLJS.Yx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.SS],null)])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);$CLJS.X(FP,hM($CLJS.sG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.SS],null)])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FP],null);$CLJS.X(PM,hM($CLJS.lG,$CLJS.H(["field-or-expression",$CLJS.wU])));
var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);$CLJS.X(JR,hM($CLJS.NG,$CLJS.H(["field-or-expression",$CLJS.wU])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(oO,hM($CLJS.XF,$CLJS.H(["field-or-expression",$CLJS.wU])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null);$CLJS.X(ZQ,hM($CLJS.EG,$CLJS.H(["field-or-expression",$CLJS.wU])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZQ],null);$CLJS.X(jO,hM($CLJS.Yn,$CLJS.H(["field-or-expression",$CLJS.wU])));
var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.X(WN,hM($CLJS.ik,$CLJS.H(["field-or-expression",$CLJS.wU])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WN],null);$CLJS.X(QN,hM($CLJS.LG,$CLJS.H(["field-or-expression",$CLJS.wU,"pred",$CLJS.WT])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QN],null);$CLJS.X(gO,hM($CLJS.AG,$CLJS.H(["pred",$CLJS.WT])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gO],null);$CLJS.X(QQ,hM($CLJS.yG,$CLJS.H(["pred",$CLJS.WT])));
var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);$CLJS.X(mN,hM($CLJS.GG,$CLJS.H(["field-or-expression",$CLJS.wU])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.X(oM,hM($CLJS.YF,$CLJS.H(["field-or-expression",$CLJS.wU])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);$CLJS.X(sO,hM($CLJS.FG,$CLJS.H(["field-or-expression",$CLJS.wU])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null);$CLJS.X(fP,hM($CLJS.cG,$CLJS.H(["field-or-expression",$CLJS.wU,"percentile",fT])));
var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fP],null);$CLJS.X(TQ,hM($CLJS.mG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qE],null),tS],null)])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);
$CLJS.X(PQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"unnamed aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(gM(YS,a))?iP:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[iP,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(nja,
new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",60,$CLJS.lG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:rP}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",64,$CLJS.NG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:wR}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==
typeof mM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",65,$CLJS.XF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:vP}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,
$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BG,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JM,"metabase/mbql/schema.cljc",76,$CLJS.GG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:JM}(),IU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eN,"metabase/mbql/schema.cljc",60,$CLJS.EG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:eN}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(Rha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,
$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.dt,"metabase/mbql/schema.cljc",60,$CLJS.Yn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(DU)?DU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.dt}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Mha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ht,"metabase/mbql/schema.cljc",60,$CLJS.ik,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:$CLJS.ht}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(mia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",18,$CLJS.mG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,zia,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.xs,Dia,$M],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:vO}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Lia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,
$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",62,$CLJS.yG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yG,$CLJS.qo,$CLJS.U($CLJS.Pj,dP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:nO}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aN,"metabase/mbql/schema.cljc",68,$CLJS.AG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.qo,$CLJS.U($CLJS.Pj,dP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:aN}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.ak,!0],
null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",66,$CLJS.LG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.LG,kR,$CLJS.U($CLJS.Pj,cR),$CLJS.qo,$CLJS.U($CLJS.Pj,dP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:sP}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",61,$CLJS.aG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aG,$CLJS.ix,$CLJS.U($CLJS.Pj,dO),$CLJS.fj,$CLJS.U($CLJS.Pj,$CLJS.U(mR,NP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:uP}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Cja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.nG,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",
68,$CLJS.FG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:uR}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Xia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,
nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.nG,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",72,$CLJS.cG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cG,kR,$CLJS.U($CLJS.Pj,cR),$CLJS.cG,$CLJS.U($CLJS.Pj,WQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(LU)?LU.H:null])):
null));return $CLJS.n(a)?a:SQ}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(bia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EM,"metabase/mbql/schema.cljc",77,$CLJS.YF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,kR,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:EM}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,
$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",74,$CLJS.sG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,$CLJS.rG,$CLJS.U($CLJS.Pj,$CLJS.U(mR,KN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:eP}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,oR,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),
$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.xi,"metabase/mbql/schema.cljc",70,$CLJS.Yx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yx,$CLJS.rG,$CLJS.U($CLJS.Pj,$CLJS.U(mR,KN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:$CLJS.xi}(),xU],null)]))],null)],null));
$CLJS.X(gQ,hM($CLJS.zM,$CLJS.H(["aggregation",PQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),tS],null)],null)])));
$CLJS.X(lR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(gM($CLJS.zM,a))?$CLJS.zM:cO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[cO,PQ],null)],null));$CLJS.X(IM,hM($CLJS.oG,$CLJS.H(["field",US])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);$CLJS.X(hP,hM($CLJS.hG,$CLJS.H(["field",US])));
OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hP],null);
mka=iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od(Gja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
OO,"metabase/mbql/schema.cljc",15,$CLJS.oG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,$CLJS.rG,$CLJS.U($CLJS.Pj,ER)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:OO}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",16,$CLJS.hG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.rG,$CLJS.U($CLJS.Pj,ER)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:KQ}(),OU],null)]));
PU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.us,$CLJS.LM,$CLJS.AN,$CLJS.LF,$CLJS.Zt,$CLJS.CB,$CLJS.GP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vE,tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),tS],null)],null);
nka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,PU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.LM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xM,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YP,wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null)],null)],null);
oka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,PU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.AN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hR,wS],null)],null)],null);
QU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,PU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null)],null);pka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vR],null);
qka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,QU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.LF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Xi,$CLJS.Os],null)],null)],null)],null);
$CLJS.RU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.GP,null,$CLJS.Zt,null,$CLJS.Ns,null,$CLJS.CB,null],null),null);rka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.us],null),$CLJS.RU);
$CLJS.X(DQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.mj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,qka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LM,nka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AN,oka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,QU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,rka],null)],null)],null)],null)],null));
var SU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,tS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,tS],null)],null)],null),ska=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,SU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.Os],null)],null)],null),TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null),UU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return $CLJS.n($CLJS.mE($CLJS.xd,
$CLJS.oQ)(a))?$CLJS.oQ:$CLJS.uE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,SU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oQ,$CLJS.Os],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uE,TU],null)],null),VU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lD,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[eja,tS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.IO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.hk],null)],null)],null),WU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,eka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),tka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.us],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.qF,null,$CLJS.$E,null,$CLJS.jF,null,$CLJS.nF,null],null),null)),XU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tR],null);
$CLJS.X(mO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),WU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),UU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VR,$CLJS.WT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),tka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.Dy,$CLJS.lA],null),XU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,VU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.mE($CLJS.GA($CLJS.jP,$CLJS.NQ),$CLJS.Te($CLJS.mE($CLJS.jP,$CLJS.NQ)))],null)],null));var uka=$CLJS.Q,YU;var ZU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null)],null);
if($CLJS.wd(ZU)&&$CLJS.E.g($CLJS.A(ZU),$CLJS.zj)){var $U=$CLJS.y(ZU);$CLJS.A($U);var aV=$CLJS.B($U),vka=$CLJS.xd($CLJS.A(aV))?aV:$CLJS.ee(null,aV),bV=$CLJS.y(vka),wka=$CLJS.A(bV),xka=$CLJS.B(bV);YU=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.R.j(wka,$CLJS.Yn,1)],null),xka)}else YU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,ZU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(oN,new $CLJS.P(null,3,5,uka,[$CLJS.Es,YU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"All join aliases must be unique."],null),function(a){return $CLJS.TL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.eF,a)))}],null)],null));var yka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);
$CLJS.X(tR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,1,[$CLJS.xt,"Distinct, non-empty sequence of Field clauses"],null),jM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),$CLJS.SS],null))],null));
$CLJS.X(lQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),WU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),$CLJS.eT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,new $CLJS.h(null,1,
[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),$CLJS.SS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,tS,$CLJS.wU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),XU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.WT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qS,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),jM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),mka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[pQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[pQ,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[gia,wS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),yka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,VU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.dm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NQ,$CLJS.jP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,
new $CLJS.h(null,1,[$CLJS.xt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.fG);b=$CLJS.J.g(b,$CLJS.aF);return $CLJS.td($CLJS.nu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.cV=$CLJS.Ig([$CLJS.KG,$CLJS.GP,iO,UN,XO,hS,DO,$CLJS.gS,$CLJS.Zt,CO,SM,$Q,WR,$CLJS.hN,SR,fO,$CLJS.bP,AO,$CLJS.Zi,$CLJS.zO,AM,$CLJS.EN,$CLJS.yO,BP,$CLJS.Ns,$CLJS.CB,uO],[new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,$CLJS.GP,null,$CLJS.Zt,null,$CLJS.Ns,null,$CLJS.CB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,$CLJS.GP,null,$CLJS.gS,null,$CLJS.bP,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Yj,$CLJS.DE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[iO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,lS,$CLJS.DE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,UN,null,$CLJS.Zt,null,$CLJS.Zi,null,AM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Yj,$CLJS.DE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[XO,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,1,[hS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
lS,$CLJS.DE,wja,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[DO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,lS,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,UN,null,$CLJS.Zt,null,$CLJS.Zi,null,AM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,lS,$CLJS.DE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[CO,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,
new $CLJS.h(null,1,[SM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Yj,$CLJS.DE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.KG,null,hS,null,SM,null,$Q,null,AO,null,$CLJS.Zi,null,AM,null,$CLJS.CB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Yj,$CLJS.DE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[WR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,null,$CLJS.hN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Yj,$CLJS.DE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[SR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Yj,$CLJS.DE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[fO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,$CLJS.GP,null,$CLJS.gS,null,$CLJS.bP,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,1,[AO,null],null),null)],null),new $CLJS.h(null,1,[NO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,null,$CLJS.zO,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,1,[AM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,null,$CLJS.EN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,
null,$CLJS.yO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,lS,$CLJS.DE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[BP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Ns,NO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.KG,null,$CLJS.Zi,null,$CLJS.Ns,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Yj,NO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.KG,null,hS,null,SM,null,$Q,null,AO,null,$CLJS.Zi,null,AM,null,$CLJS.CB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
lS,$CLJS.DE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[uO,null],null),null)],null)]);$CLJS.X(vR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.cV)));$CLJS.X(dN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.xt,"valid template tag widget type"],null),$CLJS.lA],null),$CLJS.Xg($CLJS.cV)));
$CLJS.X(IQ,hM(Yha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,tS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,tS],null)],null)],null)])));var dV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);$CLJS.X(DM,hM($CLJS.LF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.SS,dV],null)])));var eV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);$CLJS.X(yN,hM(xO,$CLJS.H(["target",dV])));
var fV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null),zka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.SS,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof eV?new $CLJS.Gc(function(){return eV},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",21,$CLJS.LF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.BJ,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,KN,MO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(eV)?eV.H:null])):null));return $CLJS.n(a)?a:tQ}(),eV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof fV?new $CLJS.Gc(function(){return fV},$CLJS.od(kja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,nP,$CLJS.Ti,$CLJS.nj,$CLJS.xk,jN,$CLJS.ui,$CLJS.jk,$CLJS.nk],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mS,"metabase/mbql/schema.cljc",20,xO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[xO,
$CLJS.BJ,$CLJS.U($CLJS.Pj,MO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(fV)?fV.H:null])):null));return $CLJS.n(a)?a:mS}(),fV],null)]))],null),dM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);
$CLJS.X(kN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.mE($CLJS.GA($CLJS.oQ,$CLJS.OF),$CLJS.Te($CLJS.mE($CLJS.oQ,$CLJS.OF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.oQ);var d=$CLJS.J.g(c,$CLJS.OF);c=$CLJS.J.g(c,$CLJS.mj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.OF,$CLJS.oQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),ska],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),TU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,pka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BJ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),zka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[dia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),
tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[Vja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),GS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[tP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[cN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,tP);b=$CLJS.J.g(b,cN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[Fja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[$ha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[fia,new $CLJS.h(null,1,[$CLJS.Ss,
!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[xja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Ns],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Ns],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Ns],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ny,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.us,jja,gja,$CLJS.qO,Wia,lja,Iia,Vha,aia,jia,wia,uja,cka,Aia,sia],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Aja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fia,
new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,dka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,wS],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Uja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Os,$CLJS.Os],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Os,
$CLJS.Os],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UK,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Os],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.WO)],null)],null));var gV=$CLJS.cM(dM);
(function(){var a=Eha();return function(b){if($CLJS.n(gV.h?gV.h(b):gV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.$L(b);throw $CLJS.ii($CLJS.oE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Jy,c,Pia,b],null));}})();