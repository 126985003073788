var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var k7,m7,n7,dra,era,fra,q7,r7,t7,u7,gra,v7,hra,ira;k7=function(a){var b=new $CLJS.h(null,3,[$CLJS.HE,$CLJS.p.h($CLJS.SE()),$CLJS.yB,$CLJS.yB.h(a),$CLJS.Fi,$CLJS.GA($CLJS.Fi,$CLJS.yB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vB,b,$CLJS.GA($CLJS.zE,$CLJS.T)(a)],null)};$CLJS.l7=function(a){return $CLJS.z5.h(a)};m7=function(a){return $CLJS.R.j(a,$CLJS.eu,$CLJS.Q4)};
n7=function(a,b){return $CLJS.E.g($CLJS.uE.h(a),$CLJS.dm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,$CLJS.tE,$CLJS.LE],null)))};dra=function(a){return $CLJS.v1(a,new $CLJS.h(null,1,[$CLJS.ME,$CLJS.zh],null))};
era=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.EH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.QA($CLJS.Ly);if($CLJS.n($CLJS.PA("metabase.lib.field",c))){var d=$CLJS.oE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Qk.g($CLJS.QZ,b)]))]));d instanceof Error?$CLJS.OA("metabase.lib.field",c,$CLJS.Hw(),d):$CLJS.OA("metabase.lib.field",
c,$CLJS.Hw.l($CLJS.H([d])),null)}return null};
fra=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.h2(a,b),f=$CLJS.n(e)?$CLJS.KW(a,e):$CLJS.KW(a,b),k=function(){var m=$CLJS.p7.h(f);if($CLJS.n(m))return m;m=$CLJS.HA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sV,$CLJS.HW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.TV.h(f);if($CLJS.n(u))return u;u=$CLJS.jP.h(f);if($CLJS.n(u))return u;u=$CLJS.dQ.h(f);return $CLJS.n(u)?u:$CLJS.aF.h(f)}())?$CLJS.R0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.QA($CLJS.Ly);if($CLJS.n($CLJS.PA("metabase.lib.field",
m))){var t=$CLJS.oE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.OA("metabase.lib.field",m,$CLJS.Hw(),t):$CLJS.OA("metabase.lib.field",m,$CLJS.Hw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?era(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.I3($CLJS.Gk.l(l,$CLJS.Zi,$CLJS.H([$CLJS.fL,$CLJS.UW,$CLJS.OW])),null),$CLJS.T,function(){var m=$CLJS.QZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.WK,$CLJS.DZ):l:null}finally{o7=d}};
q7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.yP);var f=$CLJS.I(c,2,null);c=$CLJS.fl.l($CLJS.H([function(){var k=$CLJS.yB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.yB,k],null):null}(),function(){var k=$CLJS.GA($CLJS.Fi,$CLJS.yB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Fi,k],null):null}(),function(){var k=$CLJS.zR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.UW,k],null):null}(),function(){var k=$CLJS.xG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.OW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.m2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.eu,$CLJS.VK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.eu,$CLJS.VK,$CLJS.T,f],null):function(){var k=fra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.eu,$CLJS.VK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.I3(c,d):c};r7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.BH,b):b)?$CLJS.wj:$CLJS.GA($CLJS.Fi,$CLJS.yB)(a)};
$CLJS.s7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.HE),l=$CLJS.J.g(f,$CLJS.yB),m=$CLJS.J.g(f,$CLJS.zR),t=$CLJS.J.g(f,$CLJS.Fi);e=$CLJS.J.g(f,$CLJS.yP);var u=$CLJS.J.g(f,$CLJS.JN),v=$CLJS.J.g(f,$CLJS.xG),x=$CLJS.fl.l;k=new $CLJS.h(null,2,[$CLJS.eu,$CLJS.VK,$CLJS.j1,k],null);f=$CLJS.vE.h(f);a=$CLJS.n(f)?f:$CLJS.g0.j(a,b,d);c=x.call($CLJS.fl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.vE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Fi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.yB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.OW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.UW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.eL,u):m;return $CLJS.n(e)?$CLJS.I3(u,e):u};t7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.si.h(d),c)},a))?$CLJS.Qk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Gk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.si.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
u7=function(a){var b=$CLJS.n($CLJS.R1.h(a))?null:function(){var d=$CLJS.WK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.UZ,null,$CLJS.$Z,null,$CLJS.DZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.fl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.HE,$CLJS.p.h($CLJS.SE()),$CLJS.yB,$CLJS.yB.h(a),$CLJS.Fi,r7(a)],null),function(){var d=$CLJS.K1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.yP,d],null):null}(),function(){var d=$CLJS.OW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.xG,d],
null):null}(),function(){var d=$CLJS.SW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.SW,d],null):null}(),function(){var d=$CLJS.UW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.zR,d],null):null}(),function(){var d=$CLJS.eL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.JN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.GA($CLJS.QZ,$CLJS.T):$CLJS.GA($CLJS.Zi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,b],null)};
gra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.WK.h(c),$CLJS.KZ)},$CLJS.R0.v(a,b,$CLJS.KW(a,b),new $CLJS.h(null,3,[$CLJS.z0,!1,$CLJS.F0,!0,$CLJS.v0,!1],null)))};v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.p7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);hra=new $CLJS.M(null,"earliest","earliest",-1928154382);ira=new $CLJS.M(null,"latest","latest",24323665);var lra;$CLJS.w1.m(null,$CLJS.rG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.v1(c,new $CLJS.h(null,2,[$CLJS.xG,$CLJS.zh,$CLJS.zR,dra],null)),a],null)});var o7=!1,w7=function w7(a,b){var d=$CLJS.C1(a,v7.h(b));a=$CLJS.n(v7.h(d))?w7.g?w7.g(a,d):w7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Rk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.K0.m(null,$CLJS.VK,function(a,b,c){return r7(c)});$CLJS.K0.m(null,$CLJS.rG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.xG);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.OW,d):c;return $CLJS.L0.j(a,b,c)});$CLJS.M0.m(null,$CLJS.VK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.M0.m(null,$CLJS.rG,function(a,b,c){var d=q7(a,b,c);b=$CLJS.s7(a,b,d,c);return $CLJS.n(v7.h(b))?w7(a,b):b});
$CLJS.G0.m(null,$CLJS.VK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.vE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.si),l=$CLJS.J.g(c,$CLJS.UW),m=$CLJS.J.g(c,$CLJS.c_),t=$CLJS.J.g(c,$CLJS.eL),u=$CLJS.J.g(c,$CLJS.fL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.I1.g($CLJS.H1,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.h0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.C1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.KA($CLJS.vE.h($CLJS.j0.j(a,b,t)),$CLJS.WW,"")):(u=$CLJS.J1(a,u),a=$CLJS.g0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.K1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.gB($CLJS.KA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.w5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.G0.m(null,$CLJS.rG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.zR);var k=$CLJS.J.g(f,$CLJS.yP),l=$CLJS.J.g(f,$CLJS.xG);f=$CLJS.J.g(f,$CLJS.JN);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.c_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.si,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.UW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.eL,f):e;return $CLJS.n(e)?$CLJS.g0.v(a,b,e,d):$CLJS.TE("[Unknown Field]")});
$CLJS.H0.m(null,$CLJS.VK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.H0.m(null,$CLJS.rG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=q7(a,b,c);return $CLJS.n(c)?$CLJS.I0.j(a,b,c):"unknown_field"});
$CLJS.N0.m(null,$CLJS.VK,function(a,b,c){return $CLJS.fl.l($CLJS.H([function(){var d=$CLJS.bi($CLJS.N0,$CLJS.di);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.WK.h(c),$CLJS.UZ)?function(){var d=$CLJS.b_.h(c);return $CLJS.n(d)?(d=$CLJS.c0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.TW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.vE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.a1.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.xG.h(b)});
$CLJS.a1.m(null,$CLJS.VK,function(a){return $CLJS.OW.h(a)});
$CLJS.$0.m(null,$CLJS.rG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.BH,b),e=$CLJS.wC($CLJS.SW,$CLJS.Fi,$CLJS.yB)(c);c=$CLJS.R.l(c,$CLJS.xG,b,$CLJS.H([$CLJS.Fi,d?$CLJS.wj:e,$CLJS.SW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,a],null)}b=$CLJS.SW.h(c);c=$CLJS.n(b)?$CLJS.Gk.g($CLJS.R.j(c,$CLJS.Fi,b),$CLJS.SW):c;c=$CLJS.Gk.g(c,$CLJS.xG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,a],null)});
$CLJS.$0.m(null,$CLJS.VK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.OW,b,$CLJS.H([$CLJS.SW,$CLJS.wC($CLJS.SW,$CLJS.Fi,$CLJS.yB)(a)])):$CLJS.Gk.l(a,$CLJS.OW,$CLJS.H([$CLJS.SW]))});$CLJS.c1.m(null,$CLJS.rG,function(a,b,c){return $CLJS.d1.j(a,b,q7(a,b,c))});
$CLJS.c1.m(null,$CLJS.VK,function(a,b,c){if($CLJS.Mk.g($CLJS.WK.h(c),$CLJS.KZ)){a=$CLJS.GA($CLJS.Fi,$CLJS.yB)(c);b=null==c?null:$CLJS.XN.h(c);if(null==b)var d=null;else try{var e=$CLJS.yD.h($CLJS.mj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,hra),l=$CLJS.J.g(f,ira),m=$CLJS.vna.l($CLJS.H([$CLJS.Y0.h(k),$CLJS.Y0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Ur.g?$CLJS.Ur.g(1,m):$CLJS.Ur.call(null,1,m))?$CLJS.Ui:$CLJS.n($CLJS.Ur.g?$CLJS.Ur.g(31,m):$CLJS.Ur.call(null,31,m))?$CLJS.fu:$CLJS.n($CLJS.Ur.g?
$CLJS.Ur.g(365,m):$CLJS.Ur.call(null,365,m))?$CLJS.oj:$CLJS.xj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.yC(a,$CLJS.yD)?$CLJS.Dna:$CLJS.yC(a,$CLJS.ZC)?$CLJS.Cna:$CLJS.yC(a,$CLJS.YD)?$CLJS.Bna:$CLJS.Cf;d=$CLJS.n(d)?t7(e,$CLJS.di,d):e;return $CLJS.n($CLJS.OW.h(c))?t7(d,$CLJS.o0,$CLJS.OW.h(c)):d}return $CLJS.Cf});
$CLJS.z5.m(null,$CLJS.rG,function(a){var b=null==a?null:$CLJS.PE(a);b=null==b?null:$CLJS.zR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.eu,$CLJS.y5,$CLJS.H([$CLJS.x5,function(c,d){return q7(c,d,a)}]))});$CLJS.z5.m(null,$CLJS.VK,function(a){var b=null==a?null:$CLJS.UW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.eu,$CLJS.y5,$CLJS.H([$CLJS.x5,$CLJS.Ue(a)]))});$CLJS.t5.m(null,$CLJS.rG,function(a,b){return $CLJS.QE(a,$CLJS.OE,$CLJS.H([$CLJS.zR,b]))});
$CLJS.t5.m(null,$CLJS.VK,function(a,b){return $CLJS.OE(a,$CLJS.UW,b)});$CLJS.A5.m(null,$CLJS.rG,function(a,b,c){return $CLJS.B5.j(a,b,q7(a,b,c))});
$CLJS.A5.m(null,$CLJS.VK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Fi);var d=$CLJS.J.g(b,$CLJS.XN),e=$CLJS.J.g(b,$CLJS.Uj);if($CLJS.Mk.g($CLJS.WK.h(b),$CLJS.KZ)){var f=function(){var m=null==a?null:$CLJS.A1($CLJS.$_(a));m=null==m?null:$CLJS.mA.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.zR)}(),k=$CLJS.HA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,$CLJS.Oj],null)),l=$CLJS.l7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=n7(S,l)?$CLJS.R.j(Z,$CLJS.o0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.o0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.Yn.h(k);return $CLJS.n(t)?$CLJS.ik.h(k):t}return f}())?null:$CLJS.yC(e,$CLJS.yj)?$CLJS.Qk.g(m7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.v5(),new $CLJS.h(null,2,[$CLJS.vE,$CLJS.TE("Bin every 0.1 degrees"),$CLJS.uE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.vE,$CLJS.TE("Bin every 1 degree"),$CLJS.uE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,1],null)],null),new $CLJS.h(null,2,[$CLJS.vE,$CLJS.TE("Bin every 10 degrees"),$CLJS.uE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,10],null)],null),new $CLJS.h(null,2,[$CLJS.vE,$CLJS.TE("Bin every 20 degrees"),
$CLJS.uE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,20],null)],null)],null)):$CLJS.yC(c,$CLJS.Oj)&&!$CLJS.yC(e,$CLJS.ni)?$CLJS.Qk.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.v5(),new $CLJS.h(null,2,[$CLJS.vE,$CLJS.TE("10 bins"),$CLJS.uE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.tE,$CLJS.tE,10],null)],null),new $CLJS.h(null,2,[$CLJS.vE,$CLJS.TE("50 bins"),$CLJS.uE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.tE,$CLJS.tE,50],null)],null),new $CLJS.h(null,2,[$CLJS.vE,$CLJS.TE("100 bins"),$CLJS.uE,new $CLJS.h(null,2,
[$CLJS.ME,$CLJS.tE,$CLJS.tE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.tW.m(null,$CLJS.rG,function(a){return a});
$CLJS.tW.m(null,$CLJS.VK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.WK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.HE,$CLJS.p.h($CLJS.SE()),$CLJS.Fi,$CLJS.GA($CLJS.Fi,$CLJS.yB)(a)],null),a=$CLJS.j1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,b,a],null);case "source/expressions":return k7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.zE.h(a))?k7(a):u7(a);default:return u7(a)}});
$CLJS.jra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Qk.g($CLJS.vW,k));var l=gra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Wl(function(t){return $CLJS.s2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Pk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.vW),m):null;return $CLJS.VW.l(e,f,$CLJS.OE,$CLJS.H([$CLJS.aF,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.x7=function(){function a(d,e){return $CLJS.aF.h($CLJS.KW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.kra=function(){function a(d,e){var f=$CLJS.R0.v(d,e,$CLJS.KW(d,e),new $CLJS.h(null,3,[$CLJS.z0,!1,$CLJS.F0,!1,$CLJS.v0,!1],null)),k=$CLJS.x7.g(d,e);return $CLJS.td(k)?$CLJS.Qk.g(function(l){return $CLJS.R.j(l,$CLJS.SZ,!0)},f):$CLJS.G3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
lra=function(){function a(d,e,f){var k=$CLJS.KW(d,e),l=$CLJS.E.g($CLJS.kC(f),$CLJS.gG)?$CLJS.P0:$CLJS.R0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.s2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.mra=function(){function a(d,e,f){f=$CLJS.zZ.j(d,e,f);return lra.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();