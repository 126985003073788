var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var FL,HL,JL,ML,RL;$CLJS.BL=function(a){return $CLJS.Wh($CLJS.q($CLJS.mC),a,$CLJS.vG)};$CLJS.CL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.DL=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.EL=new $CLJS.M(null,"object-id","object-id",-754527291);FL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.GL=new $CLJS.M(null,"operators","operators",-2064102509);
HL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.IL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);JL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.KL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.LL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
ML=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.NL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.OL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.PL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.QL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
RL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.SL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(HL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.FE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.nH],null)],null)],null));$CLJS.X(RL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,HL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.KE],null)],null)],null));$CLJS.X(JL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.rG],null),RL,$CLJS.AE],null));
$CLJS.X(ML,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.rG],null),HL,$CLJS.JE],null));
$CLJS.RG.g($CLJS.rG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.rG],null),HL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.JE,$CLJS.AE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.Oi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.kC(a)):null},$CLJS.xt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.pB,ML],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oB,JL],null)],null)],null));$CLJS.zF($CLJS.rG,$CLJS.vG);$CLJS.VE.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Fi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.hF});$CLJS.GF($CLJS.vB,$CLJS.H([$CLJS.AE]));$CLJS.VE.m(null,$CLJS.vB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Fi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.hF});
$CLJS.zF($CLJS.vB,$CLJS.vG);$CLJS.X(FL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.FE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.AE],null)],null)],null));$CLJS.RG.g($CLJS.gG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.gG],null),FL,$CLJS.Yj],null));
$CLJS.VE.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Fi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.hF});$CLJS.zF($CLJS.gG,$CLJS.vG);$CLJS.GF($CLJS.QF,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sE],null)],null)]));$CLJS.zF($CLJS.QF,$CLJS.vG);
$CLJS.GF($CLJS.mG,$CLJS.H([$CLJS.qt,$CLJS.hF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qE],null)],null)]));$CLJS.zF($CLJS.mG,$CLJS.vG);
$CLJS.X($CLJS.vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.XE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.zt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.It(", ",$CLJS.cH($CLJS.Jd,$CLJS.jl.g($CLJS.q($CLJS.mC),$CLJS.vG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.BL(a)}],null)],null));