var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var g1,Ena,Fna,h1,i1;$CLJS.e1=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};g1=function(a){return $CLJS.n(f1)?["(",$CLJS.p.h(a),")"].join(""):a};Ena=function(a,b,c){var d=$CLJS.KW(a,b);a=$CLJS.R0.j(a,b,d);var e=$CLJS.eB(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.eB($CLJS.T.h(f)),e)},a)};
Fna=function(a,b){var c=$CLJS.Rk.v(a,$CLJS.dQ,$CLJS.$D($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.aF.h(a))?$CLJS.Rk.v(c,$CLJS.aF,$CLJS.be,$CLJS.uW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vB,$CLJS.N,$CLJS.DW(b)],null))):c};h1=function(a,b,c){var d=$CLJS.DW(c);return $CLJS.R.l($CLJS.f0.j(a,b,c),$CLJS.WK,$CLJS.KZ,$CLJS.H([$CLJS.T,d,$CLJS.vE,d]))};i1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.j1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var k1=function(){function a(d,e,f){var k=$CLJS.KW(d,e);k=$CLJS.EH($CLJS.Ok.g($CLJS.ch([f]),$CLJS.DW),$CLJS.dQ.h(k));if($CLJS.n(k))return k;throw $CLJS.ii($CLJS.oE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.fS,f,$CLJS.OF,d,$CLJS.IL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.K0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=k1.j(a,b,c);return $CLJS.L0.j(a,b,c)});$CLJS.M0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.eu,$CLJS.VK,$CLJS.j1,$CLJS.HE.h(d),$CLJS.T,e,$CLJS.zE,e,$CLJS.vE,$CLJS.g0.j(a,b,c),$CLJS.yB,$CLJS.L0.j(a,b,c),$CLJS.WK,$CLJS.KZ],null)});$CLJS.G0.m(null,$CLJS.pB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.G0.m(null,$CLJS.nB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.G0.m(null,$CLJS.oB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.G0.m(null,$CLJS.EB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.G0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.H0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var f1=!1,Gna=new $CLJS.h(null,4,[$CLJS.bs,"+",$CLJS.qt,"-",$CLJS.cs,"×",$CLJS.MG,"÷"],null),l1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bs,$CLJS.qt,$CLJS.MG,$CLJS.cs],null)),m1=null,n1=0,o1=0;;)if(o1<n1){var Hna=m1.X(null,o1);$CLJS.zF(Hna,i1);o1+=1}else{var p1=$CLJS.y(l1);if(p1){var q1=p1;if($CLJS.Ad(q1)){var r1=$CLJS.lc(q1),Ina=$CLJS.mc(q1),Jna=r1,Kna=$CLJS.D(r1);l1=Ina;m1=Jna;n1=Kna}else{var Lna=$CLJS.A(q1);$CLJS.zF(Lna,i1);l1=$CLJS.B(q1);m1=null;n1=0}o1=0}else break}
$CLJS.G0.m(null,i1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Gna,c);a:{var e=f1;f1=!0;try{var f=$CLJS.It([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.hu($CLJS.g0,a,b),d));break a}finally{f1=e}f=void 0}return g1(f)});$CLJS.H0.m(null,i1,function(){return"expression"});
$CLJS.K0.m(null,$CLJS.KH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.BF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.L0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.L0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.G0.m(null,$CLJS.qJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.g0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.Z0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.FA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=g1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.H0.m(null,$CLJS.qJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.I0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.Z0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.FA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.G0.m(null,$CLJS.uG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.g0.v(a,b,e,d)});$CLJS.H0.m(null,$CLJS.uG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.I0.j(a,b,d)});
$CLJS.Mna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Ena(d,e,f)))throw $CLJS.ii("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.fS,f],null));return $CLJS.VW.l(d,e,Fna,$CLJS.H([$CLJS.EW($CLJS.wW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Nna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.dQ.h($CLJS.KW(d,e)));return null==f?null:$CLJS.Qk.g($CLJS.hu(h1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.s1=function(){function a(d,e){return $CLJS.Fe($CLJS.dQ.h($CLJS.KW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.tW.m(null,$CLJS.vB,function(a){return a});
$CLJS.Ona=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.cm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DW(t),m],null)},$CLJS.s1.g(d,e))),l=$CLJS.KW(d,e);d=$CLJS.R0.j(d,e,l);return $CLJS.Fe($CLJS.e1(function(m){return $CLJS.Va(f)||$CLJS.Mk.g($CLJS.WK.h(m),$CLJS.KZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Pna=function(){function a(d,e,f){return $CLJS.vW(h1(d,e,k1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();