var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var FH,GH,Ifa,IH,Jfa,Kfa,Lfa,JH,HH;$CLJS.DH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.EH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
FH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.yC(b,$CLJS.ZC)?$CLJS.oH:$CLJS.yC(b,$CLJS.YD)?$CLJS.jH:$CLJS.yC(b,$CLJS.yD)?$CLJS.lH:null;return $CLJS.n(b)?$CLJS.yF(b,a):!0};
GH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.DH(function(d){return $CLJS.yC($CLJS.WE(d),$CLJS.jD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Mk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.WE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(FH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Ifa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.xt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.zt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Dj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(GH(b))].join("")}],null),$CLJS.Te(GH)],null)],null)};
IH=function(a){var b=$CLJS.Q,c=Ifa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.xt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.xs,c,a],null)};Jfa=function(a){return $CLJS.Qd($CLJS.BF,$CLJS.hf.g(function(b){var c=$CLJS.WE(b),d=$CLJS.yC(c,$CLJS.hF);b=d?$CLJS.yF($CLJS.vG,b):d;return $CLJS.n(b)?$CLJS.Oj:c},a))};Kfa=function(a){a=$CLJS.EH(function(b){return!$CLJS.yC(b,$CLJS.jD)},$CLJS.hf.g($CLJS.WE,a));return $CLJS.yC(a,$CLJS.hF)?$CLJS.Vj:a};Lfa=function(a){return $CLJS.n($CLJS.Re(function(b){return $CLJS.yC($CLJS.WE(b),$CLJS.jD)},a))?Kfa(a):Jfa(a)};
JH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.KH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);HH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,HH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.WE(a);return $CLJS.Qe(function(d){return FH(d,c)},b)}],null)],null));
$CLJS.X(JH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null));$CLJS.RG.g($CLJS.bs,IH($CLJS.bs));$CLJS.RG.g($CLJS.qt,IH($CLJS.qt));$CLJS.IF($CLJS.cs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,JH],null)]));$CLJS.IF($CLJS.MG,$CLJS.H([$CLJS.qt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,JH],null)]));
for(var LH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,$CLJS.qt,$CLJS.cs],null)),MH=null,NH=0,OH=0;;)if(OH<NH){var Mfa=MH.X(null,OH);$CLJS.zF(Mfa,$CLJS.KH);OH+=1}else{var PH=$CLJS.y(LH);if(PH){var QH=PH;if($CLJS.Ad(QH)){var RH=$CLJS.lc(QH),Nfa=$CLJS.mc(QH),Ofa=RH,Pfa=$CLJS.D(RH);LH=Nfa;MH=Ofa;NH=Pfa}else{var Qfa=$CLJS.A(QH);$CLJS.zF(Qfa,$CLJS.KH);LH=$CLJS.B(QH);MH=null;NH=0}OH=0}else break}$CLJS.VE.m(null,$CLJS.KH,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);return Lfa(a)});
$CLJS.GF($CLJS.SF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.zF($CLJS.SF,$CLJS.lF);
for(var SH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.JF,$CLJS.eG],null)),TH=null,UH=0,VH=0;;)if(VH<UH){var Rfa=TH.X(null,VH);$CLJS.GF(Rfa,$CLJS.H([$CLJS.qt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));VH+=1}else{var WH=$CLJS.y(SH);if(WH){var XH=WH;if($CLJS.Ad(XH)){var YH=$CLJS.lc(XH),Sfa=$CLJS.mc(XH),Tfa=YH,Ufa=$CLJS.D(YH);SH=Sfa;TH=Tfa;UH=Ufa}else{var Vfa=$CLJS.A(XH);$CLJS.GF(Vfa,$CLJS.H([$CLJS.qt,$CLJS.VD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));SH=$CLJS.B(XH);TH=null;UH=0}VH=0}else break}
for(var ZH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,$CLJS.pG,$CLJS.wG],null)),$H=null,aI=0,bI=0;;)if(bI<aI){var Wfa=$H.X(null,bI);$CLJS.GF(Wfa,$CLJS.H([$CLJS.qt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));bI+=1}else{var cI=$CLJS.y(ZH);if(cI){var dI=cI;if($CLJS.Ad(dI)){var eI=$CLJS.lc(dI),Xfa=$CLJS.mc(dI),Yfa=eI,Zfa=$CLJS.D(eI);ZH=Xfa;$H=Yfa;aI=Zfa}else{var $fa=$CLJS.A(dI);$CLJS.GF($fa,$CLJS.H([$CLJS.qt,$CLJS.wj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));ZH=$CLJS.B(dI);$H=null;aI=0}bI=0}else break}$CLJS.GF($CLJS.PF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));
$CLJS.VE.m(null,$CLJS.PF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.yC($CLJS.WE(b),$CLJS.wj)&&$CLJS.yC($CLJS.WE(a),$CLJS.wj)?$CLJS.wj:$CLJS.VD});