var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var efa,ffa,gfa,hfa,ifa,jfa,kfa,XG,lfa,mfa,nfa,ofa,pfa,qfa,rfa,sfa,ZG,tfa;$CLJS.SG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);efa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.TG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);ffa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
gfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.UG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.VG=new $CLJS.M(null,"column-name","column-name",551523580);hfa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.WG=new $CLJS.M(null,"display-info","display-info",-816930907);ifa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
jfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);kfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);XG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);lfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);mfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);nfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
ofa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);pfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);qfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);rfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.YG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);sfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
ZG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.$G=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);tfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.IF($CLJS.Yx,$CLJS.H([$CLJS.qt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)],null)],null)]));$CLJS.IF($CLJS.sG,$CLJS.H([$CLJS.qt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)],null)],null)]));
$CLJS.GF($CLJS.lG,$CLJS.H([$CLJS.qt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.GF($CLJS.XF,$CLJS.H([$CLJS.qt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));$CLJS.GF($CLJS.AG,$CLJS.H([$CLJS.qt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));
$CLJS.GF($CLJS.ik,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.zF($CLJS.ik,$CLJS.lF);$CLJS.GF($CLJS.FG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.zF($CLJS.FG,$CLJS.lF);$CLJS.GF($CLJS.Yn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.zF($CLJS.Yn,$CLJS.lF);
$CLJS.X(XG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.xt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.GF($CLJS.cG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XG],null)]));$CLJS.zF($CLJS.cG,$CLJS.lF);
$CLJS.GF($CLJS.yG,$CLJS.H([$CLJS.qt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));$CLJS.GF($CLJS.GG,$CLJS.H([$CLJS.qt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.GF($CLJS.EG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));
$CLJS.GF($CLJS.NG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.zF($CLJS.EG,$CLJS.lF);$CLJS.zF($CLJS.NG,$CLJS.lF);$CLJS.GF($CLJS.LG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));$CLJS.zF($CLJS.LG,$CLJS.lF);
$CLJS.GF($CLJS.YF,$CLJS.H([$CLJS.qt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.X(ZG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.xs,sfa,qfa,jfa,ffa,efa,ifa,kfa,lfa,mfa,pfa,nfa,tfa,rfa,gfa,ofa,$CLJS.Cl],null));$CLJS.X($CLJS.TG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZG],null)],null));
$CLJS.aH=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.kG,$CLJS.Yx,$CLJS.UG,!1,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Count of rows"),$CLJS.VG,$CLJS.TE("Count"),$CLJS.Rt,$CLJS.TE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.EG,$CLJS.SG,$CLJS.ek,$CLJS.UG,!0,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Sum of ..."),$CLJS.VG,$CLJS.TE("Sum"),$CLJS.Rt,$CLJS.TE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.lG,$CLJS.SG,$CLJS.ek,$CLJS.UG,!0,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Average of ..."),$CLJS.VG,$CLJS.TE("Average"),$CLJS.Rt,$CLJS.TE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.FG,$CLJS.SG,$CLJS.ek,$CLJS.UG,!0,$CLJS.YG,$CLJS.nG,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Median of ..."),$CLJS.VG,$CLJS.TE("Median"),$CLJS.Rt,$CLJS.TE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.XF,$CLJS.SG,$CLJS.Os,$CLJS.UG,!0,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Number of distinct values of ..."),$CLJS.VG,$CLJS.TE("Distinct values"),$CLJS.Rt,$CLJS.TE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.NG,$CLJS.SG,$CLJS.ek,$CLJS.UG,!0,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Cumulative sum of ..."),
$CLJS.VG,$CLJS.TE("Sum"),$CLJS.Rt,$CLJS.TE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.kG,$CLJS.sG,$CLJS.UG,!1,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Cumulative count of rows"),$CLJS.VG,$CLJS.TE("Count"),$CLJS.Rt,$CLJS.TE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.GG,$CLJS.SG,$CLJS.ek,$CLJS.UG,
!0,$CLJS.YG,$CLJS.BG,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Standard deviation of ..."),$CLJS.VG,$CLJS.TE("SD"),$CLJS.Rt,$CLJS.TE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.Yn,$CLJS.SG,$CLJS.Yi,$CLJS.UG,!0,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Minimum of ..."),$CLJS.VG,$CLJS.TE("Min"),$CLJS.Rt,$CLJS.TE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.kG,$CLJS.ik,$CLJS.SG,$CLJS.Yi,$CLJS.UG,!0,$CLJS.YG,$CLJS.MF,$CLJS.WG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.TE("Maximum of ..."),$CLJS.VG,$CLJS.TE("Max"),$CLJS.Rt,$CLJS.TE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(hfa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.$G],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.us],null),$CLJS.hf.h($CLJS.kG),$CLJS.aH)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Xi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UG,$CLJS.Ns],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.YG,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WG,$CLJS.md],null)],null));