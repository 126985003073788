var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var m4,o4,p4,q4,Lpa;m4=function(a,b,c){var d=$CLJS.KW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.gG);d=$CLJS.EH($CLJS.Ok.j($CLJS.ch([c]),$CLJS.HE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ii($CLJS.oE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Ms,c,$CLJS.OF,a,$CLJS.IL,b],null));return d};$CLJS.n4=function(a){return $CLJS.g2($CLJS.EG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
o4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);p4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);q4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Lpa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.J0.m(null,$CLJS.gG,function(a,b){var c=$CLJS.Fe($CLJS.gG.h($CLJS.KW(a,b)));return $CLJS.n(c)?$CLJS.i2($CLJS.TE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.g0.v(a,b,v,$CLJS.h0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.g0.v(a,
b,t,$CLJS.h0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.M0.m(null,$CLJS.gG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.yB);e=$CLJS.J.g(e,$CLJS.Fi);c=$CLJS.I(c,2,null);c=m4(a,b,c);return $CLJS.fl.l($CLJS.H([$CLJS.f0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.WK,$CLJS.mL,$CLJS.j1,$CLJS.HE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.yB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Fi,e],null):null]))});
$CLJS.G0.m(null,$CLJS.gG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.g0.v(a,b,m4(a,b,c),d)});$CLJS.zF(p4,q4);
for(var r4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yx,$CLJS.sG],null)),s4=null,t4=0,u4=0;;)if(u4<t4){var Mpa=s4.X(null,u4);$CLJS.zF(Mpa,p4);u4+=1}else{var v4=$CLJS.y(r4);if(v4){var w4=v4;if($CLJS.Ad(w4)){var x4=$CLJS.lc(w4),Npa=$CLJS.mc(w4),Opa=x4,Ppa=$CLJS.D(x4);r4=Npa;s4=Opa;t4=Ppa}else{var Qpa=$CLJS.A(w4);$CLJS.zF(Qpa,p4);r4=$CLJS.B(w4);s4=null;t4=0}u4=0}else break}
$CLJS.G0.m(null,p4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.g0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.oE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.oE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.TE("Count");case "cum-count":return $CLJS.TE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.H0.m(null,p4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.M0.m(null,p4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.bi($CLJS.M0,q4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Uj,$CLJS.$C)});$CLJS.G0.m(null,$CLJS.aG,function(){return $CLJS.TE("Case")});
$CLJS.H0.m(null,$CLJS.aG,function(){return"case"});$CLJS.zF(o4,q4);for(var y4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.lG,$CLJS.NG,$CLJS.XF,$CLJS.ik,$CLJS.FG,$CLJS.Yn,$CLJS.GG,$CLJS.EG,$CLJS.YF],null)),z4=null,A4=0,B4=0;;)if(B4<A4){var Rpa=z4.X(null,B4);$CLJS.zF(Rpa,o4);B4+=1}else{var C4=$CLJS.y(y4);if(C4){var D4=C4;if($CLJS.Ad(D4)){var E4=$CLJS.lc(D4),Spa=$CLJS.mc(D4),Tpa=E4,Upa=$CLJS.D(E4);y4=Spa;z4=Tpa;A4=Upa}else{var Vpa=$CLJS.A(D4);$CLJS.zF(Vpa,o4);y4=$CLJS.B(D4);z4=null;A4=0}B4=0}else break}
$CLJS.H0.m(null,o4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.G0.m(null,o4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.g0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.oE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.oE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.oE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.oE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.oE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.oE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.oE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.oE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.oE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.G0.m(null,$CLJS.cG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.oE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.g0.v(a,b,e,d)]))});$CLJS.H0.m(null,$CLJS.cG,function(){return"percentile"});
$CLJS.zF($CLJS.cG,q4);$CLJS.G0.m(null,$CLJS.LG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.oE("Sum of {0} matching condition",$CLJS.H([$CLJS.g0.v(a,b,e,d)]))});$CLJS.H0.m(null,$CLJS.LG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.I0.j(a,b,d))].join("")});$CLJS.zF($CLJS.LG,q4);$CLJS.G0.m(null,$CLJS.yG,function(){return $CLJS.TE("Share of rows matching condition")});
$CLJS.H0.m(null,$CLJS.yG,function(){return"share"});$CLJS.zF($CLJS.yG,q4);$CLJS.G0.m(null,$CLJS.AG,function(){return $CLJS.TE("Count of rows matching condition")});$CLJS.H0.m(null,$CLJS.AG,function(){return"count-where"});$CLJS.zF($CLJS.AG,q4);
$CLJS.M0.m(null,q4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.fl.l;d=$CLJS.n(d)?$CLJS.dm($CLJS.f0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gL],null)):null;var f=$CLJS.bi($CLJS.M0,$CLJS.di);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.fl,$CLJS.H([d,a]))});
$CLJS.Wpa=function(){function a(d){return $CLJS.g2($CLJS.Yx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.g2($CLJS.Yx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.tW.m(null,$CLJS.gG,function(a){return a});
$CLJS.F4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.kC(f),$CLJS.ZK))f=$CLJS.vW(f);else return $CLJS.j2(d,e,$CLJS.gG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.G4=function(){function a(d,e){return $CLJS.Fe($CLJS.gG.h($CLJS.KW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.H4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.gG.h($CLJS.KW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.f0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.yB.h(l);return m.call($CLJS.R,$CLJS.f2(l,$CLJS.Fi,$CLJS.n(t)?t:$CLJS.Aj),$CLJS.WK,$CLJS.mL,$CLJS.H([$CLJS.j1,$CLJS.HE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.G0.m(null,$CLJS.$G,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.WG);return $CLJS.vE.h(a.o?a.o():a.call(null))});$CLJS.N0.m(null,$CLJS.$G,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.kG);b=$CLJS.J.g(d,$CLJS.WG);c=$CLJS.J.g(d,$CLJS.UG);d=$CLJS.J.g(d,$CLJS.SZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.E0,$CLJS.dB(a),$CLJS.H([$CLJS.C0,c]));return null!=d?$CLJS.R.j(a,$CLJS.o0,d):a});
$CLJS.Xpa=function(){function a(d,e){var f=function(){var m=$CLJS.mA.h($CLJS.A1($CLJS.$_(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.KW(d,e),l=$CLJS.R0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Ok.j($CLJS.kf(function(m){m=$CLJS.YG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Wl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.UG),u=$CLJS.J.g(m,$CLJS.SG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.Os))return $CLJS.R.j(m,$CLJS.HW,l);t=$CLJS.Fe($CLJS.e1(function(v){return $CLJS.C_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.HW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.eu,$CLJS.$G)})),$CLJS.aH))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ypa=function(){function a(d,e){return $CLJS.uW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG.h(d),$CLJS.N,$CLJS.wW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.UG.h(d)))return $CLJS.uW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG.h(d),$CLJS.N],null));var e=$CLJS.kG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ii(e,new $CLJS.h(null,1,[Lpa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();