var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var B1;$CLJS.x1=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Qe(function(c){return $CLJS.Hd(b,c)},a)};$CLJS.y1=function(a,b){return $CLJS.NA($CLJS.Te(a),b)};$CLJS.z1=function(a){var b;a:for(b=a;;)if($CLJS.xd(b))b=$CLJS.Zi.h(b);else{b=$CLJS.Fd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.oE("Not something with an ID: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a]))])));};
$CLJS.A1=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=$CLJS.A1[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$CLJS.A1._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("MetadataProvider.database",a);}return a};B1=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=B1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=B1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.field",a);}return a};
$CLJS.C1=function(a,b){return B1($CLJS.$_(a),b)};$CLJS.w1.m(null,$CLJS.mW,function(a){return $CLJS.v1(a,new $CLJS.h(null,2,[$CLJS.mj,$CLJS.zh,$CLJS.tL,$CLJS.Ve($CLJS.Qk,$CLJS.w1)],null))});$CLJS.M0.m(null,$CLJS.mW,function(){throw $CLJS.ii("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.O0.m(null,$CLJS.mW,function(a,b,c,d){return $CLJS.P0.v(a,b,$CLJS.KW(c,b),d)});$CLJS.G0.m(null,$CLJS.mW,function(a,b,c,d){return $CLJS.g0.v(a,b,$CLJS.KW(c,b),d)});
$CLJS.D1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.eu.h($CLJS.KW(f,0))},e,a,b,c,d)}();$CLJS.D1.m(null,$CLJS.di,function(){return!0});
$CLJS.E1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.eu,$CLJS.mW,$CLJS.pE,$CLJS.$_(e),$CLJS.ZP,d,$CLJS.tL,f],null)}function b(d,e){var f=$CLJS.Zi.h($CLJS.A1($CLJS.$_(d)));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.F1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.mC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.kC(k)},e,a,b,c,d)}();$CLJS.F1.m(null,$CLJS.uB,function(a,b){b=$CLJS.dZ.h(b);return $CLJS.E1.g(a,$CLJS.tL.h(b))});
$CLJS.F1.m(null,$CLJS.mW,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.$Y);var d=$CLJS.$_(a),e=$CLJS.Gk.g($CLJS.R.j(c,$CLJS.pE,d),$CLJS.$Y);a=$CLJS.tL.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.tL,$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.he(v,$CLJS.rG))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.Te($CLJS.GA($CLJS.yB,$CLJS.Fi))(x)))try{var z=$CLJS.F(t,2);if($CLJS.n($CLJS.mE($CLJS.Al,
$CLJS.Tl)(z))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.dm($CLJS.C1(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.Fi],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,$CLJS.fl.l($CLJS.H([K,G])),C],null)}throw $CLJS.Y;}catch(ha){if(ha instanceof Error){var S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(S=ha,S===$CLJS.Y)try{if(v=
$CLJS.F(t,0),$CLJS.he(v,$CLJS.vB))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.Te($CLJS.GA($CLJS.yB,$CLJS.Fi))(x))){G=$CLJS.F(t,1);var V=$CLJS.F(t,2);K=function(){try{return $CLJS.y1(function(ra){return $CLJS.E.g($CLJS.Aj,ra)},$CLJS.dm($CLJS.hd($CLJS.Pna.j(e,k,V)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.Fi],null)))}catch(ra){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vB,$CLJS.fl.l($CLJS.H([K,G])),V],null)}throw $CLJS.Y;}catch(ra){if(ra instanceof Error){var Z=ra;if(Z===$CLJS.Y)throw $CLJS.Y;
throw Z;}throw ra;}else throw $CLJS.Y;}catch(ra){if(ra instanceof Error){Z=ra;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw ra;}else throw S;else throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)return $CLJS.dX(u,m,t);throw S;}throw ha;}}($CLJS.Cf,f)}),$CLJS.cm($CLJS.Rs,a))):e});$CLJS.F1.m(null,$CLJS.$K,function(a,b){return $CLJS.E1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.eu,$CLJS.$V,$CLJS.jP,$CLJS.z1(b)],null)],null))});
$CLJS.F1.m(null,$CLJS.lL,function(a,b){return $CLJS.E1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.eu,$CLJS.$V,$CLJS.TV,$CLJS.z1(b)],null)],null))});