var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.ZJ=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.$J=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.aK=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var bK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.xs],null)),cK=null,dK=0,eK=0;;)if(eK<dK){var Zga=cK.X(null,eK);$CLJS.IF(Zga,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));eK+=1}else{var fK=$CLJS.y(bK);if(fK){var gK=fK;if($CLJS.Ad(gK)){var hK=$CLJS.lc(gK),$ga=$CLJS.mc(gK),
aha=hK,bha=$CLJS.D(hK);bK=$ga;cK=aha;dK=bha}else{var cha=$CLJS.A(gK);$CLJS.IF(cha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));bK=$CLJS.B(gK);cK=null;dK=0}eK=0}else break}$CLJS.GF($CLJS.zs,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)]));
for(var iK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.IG],null)),jK=null,kK=0,lK=0;;)if(lK<kK){var dha=jK.X(null,lK);$CLJS.IF(dha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)],null)],null)]));lK+=1}else{var mK=$CLJS.y(iK);if(mK){var nK=mK;if($CLJS.Ad(nK)){var oK=$CLJS.lc(nK),eha=$CLJS.mc(nK),
fha=oK,gha=$CLJS.D(oK);iK=eha;jK=fha;kK=gha}else{var hha=$CLJS.A(nK);$CLJS.IF(hha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)],null)],null)]));iK=$CLJS.B(nK);jK=null;kK=0}lK=0}else break}
for(var pK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xr,$CLJS.Zr,$CLJS.Tr,$CLJS.Vr],null)),qK=null,rK=0,sK=0;;)if(sK<rK){var iha=qK.X(null,sK);$CLJS.GF(iha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)]));sK+=1}else{var tK=$CLJS.y(pK);if(tK){var uK=tK;if($CLJS.Ad(uK)){var vK=$CLJS.lc(uK),jha=$CLJS.mc(uK),kha=vK,lha=$CLJS.D(vK);pK=jha;qK=kha;rK=lha}else{var mha=$CLJS.A(uK);$CLJS.GF(mha,$CLJS.H([$CLJS.qt,$CLJS.Kj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)]));pK=$CLJS.B(uK);qK=null;rK=0}sK=0}else break}$CLJS.GF($CLJS.UF,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)]));
$CLJS.GF($CLJS.$F,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)]));
for(var wK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,$CLJS.dG],null)),xK=null,yK=0,zK=0;;)if(zK<yK){var nha=xK.X(null,zK);$CLJS.GF(nha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)]));zK+=1}else{var AK=$CLJS.y(wK);if(AK){var BK=AK;if($CLJS.Ad(BK)){var CK=$CLJS.lc(BK),oha=$CLJS.mc(BK),pha=CK,qha=$CLJS.D(CK);wK=oha;xK=pha;yK=qha}else{var rha=$CLJS.A(BK);$CLJS.GF(rha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)]));wK=$CLJS.B(BK);
xK=null;yK=0}zK=0}else break}
for(var DK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VF,$CLJS.bG],null)),EK=null,FK=0,GK=0;;)if(GK<FK){var sha=EK.X(null,GK);$CLJS.GF(sha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)]));GK+=1}else{var HK=$CLJS.y(DK);if(HK){var IK=HK;if($CLJS.Ad(IK)){var JK=$CLJS.lc(IK),tha=$CLJS.mc(IK),uha=JK,vha=$CLJS.D(JK);DK=tha;EK=uha;FK=vha}else{var wha=$CLJS.A(IK);$CLJS.GF(wha,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)]));DK=$CLJS.B(IK);
EK=null;FK=0}GK=0}else break}
for(var KK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null),LK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.iG,$CLJS.CG,$CLJS.qG,$CLJS.jG],null)),MK=null,NK=0,OK=0;;)if(OK<NK){var PK=MK.X(null,OK);$CLJS.RG.v(PK,$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,PK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.FE,KK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null));OK+=1}else{var QK=$CLJS.y(LK);if(QK){var RK=QK;if($CLJS.Ad(RK)){var SK=$CLJS.lc(RK),xha=$CLJS.mc(RK),yha=SK,zha=$CLJS.D(SK);LK=xha;MK=yha;NK=zha}else{var TK=$CLJS.A(RK);$CLJS.RG.v(TK,$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,TK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.FE,KK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.pF],null)],null));LK=$CLJS.B(RK);MK=null;NK=0}OK=0}else break}
$CLJS.RG.v($CLJS.DG,$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.DG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.FE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,$CLJS.$v,$CLJS.by,$CLJS.ZF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.sF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lH],null)],null));$CLJS.RG.v($CLJS.QF,$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.QF],null),$CLJS.FE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.rE,$CLJS.AE],null)],null));
$CLJS.X($CLJS.ZJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.$J],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.us,$CLJS.$r,$CLJS.IG,$CLJS.$F,$CLJS.UF,$CLJS.Xr,$CLJS.Tr,$CLJS.Zr,$CLJS.Vr,$CLJS.TF,$CLJS.dG,$CLJS.VF,$CLJS.bG,$CLJS.qG,$CLJS.jG,$CLJS.iG,$CLJS.CG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.Xi],null)],null));