var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var AZ,Hma,Ima,Jma,Kma,Lma,GZ,Mma,Nma,Oma,JZ,Pma,Qma,Rma,OZ,PZ,Sma,Tma,Uma,YZ,Vma,Wma,Xma,a_,Yma,Zma,$ma,ana;AZ=function(){};$CLJS.BZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(AZ,a):$CLJS.$a(AZ,a)};Hma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.CZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Ima=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Jma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.DZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.EZ=new $CLJS.M(null,"database-id","database-id",1883826326);Kma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.FZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Lma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);GZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Mma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Nma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Oma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.HZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.IZ=new $CLJS.M(null,"dataset","dataset",1159262238);JZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Pma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.KZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.LZ=new $CLJS.M(null,"definition","definition",-1198729982);Qma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.MZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Rma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.NZ=new $CLJS.M("source","joins","source/joins",1225821486);OZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
PZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.QZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.RZ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Sma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.SZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Tma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Uma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.TZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.UZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.VZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.WZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.XZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);YZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Vma=new $CLJS.M(null,"details","details",1956795411);$CLJS.ZZ=new $CLJS.M("source","fields","source/fields",-649667981);Wma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.$Z=new $CLJS.M("source","native","source/native",-1444604147);
Xma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);a_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.b_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Yma=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Zma=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$ma=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);ana=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.c_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.d_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(OZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.us,$CLJS.UZ,$CLJS.$Z,$CLJS.DZ,$CLJS.FZ,$CLJS.ZZ,$CLJS.mL,$CLJS.hL,$CLJS.NZ,$CLJS.KZ,$CLJS.MZ],null));$CLJS.X(YZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,ana],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bL,$CLJS.JE],null)],null));
$CLJS.X(GZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,Yma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Os],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Lma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Os],null)],null)],null));
$CLJS.X($CLJS.HZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.VK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.KE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.JE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Yj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.KE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.JE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.JE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c_,
new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WK,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.b_,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.cL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,new $CLJS.h(null,2,[$CLJS.Yn,1,$CLJS.ik,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Sma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$ma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GZ],null)],null)],null)],null));
$CLJS.X(a_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TZ,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[Oma,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.KE],null)],null)],null)],null)],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VZ,$CLJS.Ns],null),new $CLJS.P(null,2,5,$CLJS.Q,[Qma,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TZ,$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UK,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,
$CLJS.AE],null)],null)],null));
$CLJS.X(Wma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.lL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EZ,$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.hk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,
new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.hk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.YK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));
$CLJS.X(Tma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.XK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.sE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Rt,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.AE],null)],null)],null));
$CLJS.X(Uma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.AE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Rt,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.AE],null)],null)],null));
$CLJS.X(Xma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.AE],null)],null)],null));
$CLJS.X(Ima,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.jL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.hk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.d_,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Xi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mA,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.Xi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.hk],null)],null)],null));
$CLJS.X(PZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.BZ},Kma,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.xk,$CLJS.ui,$CLJS.jk,$CLJS.nk],[Hma,Zma,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Yq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.BZ)?$CLJS.BZ.H:null]))],null));
$CLJS.X(Mma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PZ],null)],null)],null)],null));